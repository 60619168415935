import React from 'react';
import Axios from 'axios';
import { Switch, Route } from 'react-router-dom';

import { SubNavigation, UserRoleContext, PlanModel } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import DashboardIcon from '@material-ui/icons/Dashboard';
import PublicIcon from '@material-ui/icons/Public';

import AppNavigation from '../../AppNavigation/AppNavigation';
import CompanyDetails from './CompanyDetails/CompanyDetails';
import CompanySitesProfileModel from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileModel';
import CompanySitesSettings from './CompanySitesSettings/CompanySitesSettings';

class Companies extends React.Component {
  state = {
    companySitesProfile: {}
  }

  componentDidMount = () => {
    return this.fetchCompanySitesProfile();
  }

  fetchCompanySitesProfile = () => {
    return Axios.get(
      `/api/sites/company_sites_profiles/${ this.props.match.params.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const companySitesProfile = CompanySitesProfileModel.fromJSON(
        response.data.company_sites_profile
      );

      return this.setState({ companySitesProfile });
    })
  }


  render(){
    var subNavLinks = [
      { primaryText: 'Details', path: `/companies/${ this.props.match.params.id }`, exactPathMatch: true, icon: <DashboardIcon /> }
    ];

    if( this.state.companySitesProfile && this.state.companySitesProfile.id ){
      subNavLinks.push({
        primaryText: 'Sites',
        path: `/companies/${ this.props.match.params.id }/sites`,
        icon: <PublicIcon />
      });
    }

    return (
      <AppNavigation title="Companies">
        <Switch>
          <SubNavigation links={subNavLinks}>
            <Route path="/companies/:id/sites" component={ CompanySitesSettings }/>
            <Route exact path={['/companies/:id/user_invitations/new', '/companies/:id']} component={ CompanyDetails }/>
          </SubNavigation>
        </Switch>
      </AppNavigation>
    );
  }

}

export default withContext(
  UserRoleContext,
  Companies
);