import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import { FeedContext } from 'Social/Feed/FeedProvider';
import CheckoutSetup from './CheckoutSetup/CheckoutSetup';
import CompanyFeedSetup from './CompanyFeedSetup/CompanyFeedSetup';
import FacebookAccountSetup from 'SharedSetup/FacebookAccountSetup';
import SocialPlanSetup from './SocialPlanSetup/SocialPlanSetup';
import SocialSetupCompletion from './SocialSetupCompletion/SocialSetupCompletion';
import SocialUndoResubscription from 'Social/CompanySocial/SocialUndoResubscription/SocialUndoResubscription';
import TransitionProvider from 'TransitionProvider/TransitionProvider';

class SocialSetupProcess extends React.Component {
  componentDidMount = () => {
    if(!this.props.companySocialProfile) {
      this.props.createCompanySocialProfile();
    }
  }

  render() {
    return (
      this.props.companySocialProfile ?
        <WrappedTransitionProvider initialStatePath="/social/setup" machineStates={machineStates} />
      :
        null
    );
  }
}


export default withContext(CompanySocialProfileContext, SocialSetupProcess);


// 1) Wrap the Transition Provider with the Providers it will need to evaluate transitions
const WrappedTransitionProvider = withContext(
  CompanySocialProfileContext,
  PlanContext,
  CompanyRoleContext,
  FeedContext,
  TransitionProvider
);

// 2) Define the steps of the process, each with the component, a path, optionally `exact` for the path, and a transitions object. At minimum, the transitions object should define a 'next' key, which is a function of props that returns an object of the form `{ path: String, params: Object?, query: Object? } `
// back: { path: '/social/setup/notification_preferences' },
// next: props => (
//   { path: '/social/setup/payment_method', query: { selected_plan_id: props.selectedPlanId } }
// ),Yu


const sharedState = {
  selectedPlanId: null
};


const guardSelectedPlanId = props => (
  !sharedState.selectedPlanId ? '/social/setup/plans' : null
);


const machineStates = [
  // Select a Plan
  {
    component: props => <SocialPlanSetup {...props} selectedPlanId={sharedState.selectedPlanId}/>,
    path: '/social/setup/plans',
    guard: props => {
      if( !props.isSocialAvailable ){
        return '/'
      }else if( props.companySocialProfile.isSetupComplete ){
        return '/social';
      }

      if (props.upgradableSocialPlans.length === 1) {
        sharedState.selectedPlanId = sharedState.selectedPlanId || props.upgradableSocialPlans[0].id;
        return '/social/setup/feeds';
      }

      return null;
    },
    transitions: {
      next: props => {
        sharedState.selectedPlanId = props.selectedPlanId;
        return '/social/setup/feeds';
      }
    }
  },
  // Select Feeds
  {
    path: '/social/setup/feeds',
    component: props => <CompanyFeedSetup selectedPlanId={sharedState.selectedPlanId} {...props} showBack={props.upgradableSocialPlans.length > 1} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/social/setup/plans',
      next: '/social/setup/facebook',
    }
  },
  // Link Facebook
  {
    path: '/social/setup/facebook',
    component: props => (
      <FacebookAccountSetup
        {...props}
        shouldEnqueueReviewsImporter={false}
        shouldConnectBusinessManager={false}
        productUndoResubscriptionAlert={SocialUndoResubscription}
      />
    ),
    guard: props => props.companyFeeds.length ? null : '/social/setup/feeds',
    transitions: {
      back: '/social/setup/feeds',
      next: '/social/setup/checkout',
    }
  },
  {
    path: '/social/setup/checkout',
    component: props => <CheckoutSetup {...props} selectedPlanId={sharedState.selectedPlanId} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/social/setup/facebook',
      next: '/social/setup/done',
    }
  },
  {
    path: '/social/setup/done',
    component: props => <SocialSetupCompletion {...props} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/social',
      next: '/social',
    }
  },
];
