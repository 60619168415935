import React from 'react';
import Axios from 'axios';

import { Ability, SnackbarContext, UserRoleContext } from 'go-boost-library-react';
import { log, withContext } from 'kn-react';

import { Typography, withStyles, Paper, IconButton, LinearProgress, List, FormControlLabel, Switch, Toolbar, ListItem, ListItemText, ListItemSecondaryAction, Avatar, ListItemIcon, Hidden } from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider';
import CompanyReviewSiteModel from 'Reviews/CompanyReviewSiteModel';


class CompanyReviewSitesCard extends React.Component {
  state = {
    dialogOpen: false
  };

  toggleSiteVisibility = (reviewSiteId) => {
    if (!this.props.currentUserRole.roleHasAbility( Ability.EDIT_REVIEWS_COMPANY_REVIEW_SITE_VISIBILITY )) {
      return;
    }

    const reviewSite = this.props.companyReviewSites.find(r => r.id === reviewSiteId);

    return Axios.put(
      `/api/reviews/company_review_sites/${ reviewSite.id }`,
      {
        is_customer_shown: !reviewSite.isCustomerShown
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    )
    .then(response => {
      this.props.onUpdateCompanyReviewSite(CompanyReviewSiteModel.fromJSON(response.data.company_review_site));
      this.props.showSnackbar('Company Review Site successfully updated.')
    });
  }

  siteListItem = companyReviewSite => {
    const { classes, onEdit, getReviewSourceById, currentUserRole } = this.props;

    const reviewSource = getReviewSourceById(companyReviewSite.reviewSourceId);

    const icon = (
      <ListItemIcon>
        { reviewSource.getAvatar() }
      </ListItemIcon>
    );

    const text = (
      <ListItemText
        classes={{ secondary: classes.ellipsis }}
        primary={reviewSource.name}
        secondary={companyReviewSite.url}
      />
    );

    const secondaryAction = (
      <ListItemSecondaryAction>
        <Toolbar>
          {
            currentUserRole.roleHasAbility( Ability.EDIT_REVIEWS_COMPANY_REVIEW_SITE_VISIBILITY ) ?
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={companyReviewSite.isCustomerShown}
                    onClick={e => this.toggleSiteVisibility(companyReviewSite.id)}
                  />
                }
                label="Visible to Customers"
                labelPlacement="start"
              />
            :
              null
          }
          <IconButton
            component="a"
            href={companyReviewSite && companyReviewSite.url ? companyReviewSite.url : '#'}
            target="_blank"
          >
            <OpenInNew />
          </IconButton>
        </Toolbar>
      </ListItemSecondaryAction>
    );
    log("companyReviewSite",companyReviewSite);
    return (
      <div key={companyReviewSite.id}>
        <Hidden smDown>
          <ListItem>
            { icon }
            { text }
            { secondaryAction }
          </ListItem>
        </Hidden>

        <Hidden mdUp>
          <ListItem>
            { icon }
            { text }
          </ListItem>
          <ListItem>
            { secondaryAction }
          </ListItem>
        </Hidden>
      </div>
    )
  }

  render() {
    const { classes, loading, companyReviewSites, currentUserRole } = this.props;

    return (
      <Paper className={classes.paperRoot}>
        <Typography variant="subtitle1" color="primary">Review Sites</Typography>

        {loading ? <LinearProgress /> : null}

        {
          (!loading && !companyReviewSites.length) ?
            <Typography variant="caption" className={classes.noData}>No Review Sites.</Typography>
            :
            <List>
              {(companyReviewSites) ? companyReviewSites.map(r => this.siteListItem(r)) : null}
            </List>
        }
      </Paper>
    );
  }
}

const styles = theme => ({
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 5
  },
  avatarText: {
    lineHeight: '30px'
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  paperRoot: {
    padding: 16
  }
})

CompanyReviewSitesCard.defaultProps = {
  onEdit: siteId => {},
  onAdd: () => {},
  loading: true,
  onUpdateCompanyReviewSite: () => {},
}

export default withContext(
  CompanyReviewsProfileContext,
  ReviewSourcesContext,
  UserRoleContext,
  SnackbarContext,
  withStyles(styles)(CompanyReviewSitesCard)
);