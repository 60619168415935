import React from 'react';

import { Ability, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { withStyles, Grid } from '@material-ui/core';

import OrganizationUserCard from '../OrganizationUserCard/OrganizationUserCard';
import OrganizationUserInvitationCard from 'Settings/UserInvitations/OrganizationUserInvitations/OrganizationUserInvitationCard/OrganizationUserInvitationCard';


class UserIndex extends React.Component {


  render() {
    const { classes } = this.props;


    const showViewAllUsers = (
      (
        this.props.organization &&
        this.props.organization.isGoBoost() &&
        this.props.currentUserRole.roleHasAbility(
          Ability.ORPHAN_USER
        )
      ) ?
        true
      :
        false
    );

    return (
      <div className={classes.root}>
        <Grid
          spacing={16}
          container
        >
          <Grid item xs={12}>
            <OrganizationUserCard
              organization={this.props.organization}
              showDescendantsInfo={true}
              showViewAllUsers={showViewAllUsers}
            />
          </Grid>

          <Grid item xs={12}>
            <OrganizationUserInvitationCard
              organization={this.props.organization}
              newInvitationPath="/users/user_invitations/new"
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16,
  }
});

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    UserIndex
  )
);