import React from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Typography, withStyles, Paper, IconButton, TableBody, Grid, Select, MenuItem } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import { SmallTablePagination, UserRoleContext } from 'go-boost-library-react';

import { TableToolbar, StarNumberRating, TextStatusIndicator, ReviewListItem } from 'go-boost-library-react';
import { log, withContext, format } from 'kn-react';
import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import ReviewModel from 'Reviews/ReviewModel';
import { ReviewSourcesContext } from 'Reviews/ReviewSourcesProvider';


class ReviewsTable extends React.Component {
  state = {
    isCompanyView: true,
    total: 0,
    page: 0,
    reviews: [],
    loading: true,
    reviewSource: 'all'
  }

  componentDidMount = () => {
    const isCompanyView = this.props.currentUserRole.isCompanyRole();

    this.setState(
      { isCompanyView },
      () => {
        return this.fetchReviews()
          .then(this.props.fetchRelevantReviewSources)
      }
    );
  }

  fetchReviews = () => {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;
    const { reviewSource, isCompanyView } = this.state;

    let url = `/api/reviews/`;

    if (isCompanyView) {
      url += `companies/${this.props.companyReviewsProfile.companyId}`;
    } else {
      url += `organizations/${currentUserRole.roleTypeId}`
    }

    return Axios.get(
      url,
      {
        headers: getUserRoleAuthHeaders(),
        params: {
          review_source_id: (reviewSource === 'all') ? reviewSource : reviewSource.id,
          limit: 25,
          page: this.state.page
        }
      }
    )
      .then(response => {
        const reviews = response.data.reviews.map(r => ReviewModel.fromJSON(r));
        const total = (reviews.length) ? reviews[0].totalReviews : 0;
        log('Reviews Models', reviews)
        this.setState({ reviews, loading: false, total })
      })
  }


  changePage = (page) => {
    this.setState(
      { page },
      this.fetchReviews
    )
  }


  onSourceChange = reviewSource => {
    this.setState(
      { reviewSource },
      this.fetchReviews
    )
  }

  render() {
    const { classes, relevantReviewSources } = this.props;

    const { total, page, reviews, loading, reviewSource, isCompanyView } = this.state;

    return (
      <Grid container spacing={16}>

        <Grid item xs={12} sm={3}>
          <Select
            label="Source"
            value={reviewSource}
            onChange={e => this.onSourceChange(e.target.value)}
            fullWidth
          >
            <MenuItem value="all">All Sources</MenuItem>
            {
              relevantReviewSources.map((s, i) => (
                <MenuItem key={i} value={s}>{s.name}</MenuItem>
              ))
            }
          </Select>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ paddingTop: 5 }}>
            <TableToolbar title={`${format.commas(total)} Review${total === 1 ? '' : 's'}`} />

            {loading ? <LinearProgress /> : null}

            {
              (!loading && !reviews.length) ?
                <Typography variant="caption" className={classes.noData}>No Reviews.</Typography>
                :
                <>
                  <List>
                    {
                      reviews ?
                        reviews.map(r => {
                          const s = relevantReviewSources.find(s => r.reviewSourceId === s.id);
                          return (
                            <ReviewListItem
                              key={r.id}
                              review={r}
                              to={`/reviews/${r.id}`}
                              icon={s ? s.getAvatar() : null}
                              showCompanyName={!isCompanyView}
                              maxChars={250}
                            />
                          );
                        })
                        : null
                    }
                  </List>

                  <div style={{ textAlign: 'right' }}>
                    <SmallTablePagination
                      count={total || 0}
                      rowsPerPage={25}
                      page={page}
                      onChangePage={(e, p) => this.changePage(p)}
                      rowsPerPageOptions={[25]}
                    />
                  </div>
                </>
            }
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

const styles = theme => ({
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 5
  },
  avatarText: {
    lineHeight: '30px'
  }
})

ReviewsTable.defaultProps = {

}

export default withContext(
  UserRoleContext,
  CompanyReviewsProfileContext,
  ReviewSourcesContext,
  withStyles(styles)(
    ReviewsTable
  )
);