import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { withContext } from 'kn-react';
import history from 'history.js';

import { UserRoleContext } from 'go-boost-library-react';

import FeedItemModel from 'Social/FeedItem/FeedItemModel';
import CompanyFeedItemModel from 'Social/CompanySocial/Feed/CompanyFeedItemModel';
import SocialTimeline from 'Social/Timeline/SocialTimeline';
import FeedItemPreview from 'Social/FeedItem/FeedItemPreview';

class FeedTimeline extends React.Component {
  state = {
    feedItems: [],
    companyFeedItems: [],
    isLoading: false,
  }


  componentDidMount = () => {
    return this.setState(
      { isLoading: true },
      () => this.fetchFeedItems()
        .then(feedItems => this.fetchCompanyFeedItems(
          feedItems.map(f => +f.id)
        ))
        .finally(() => this.setState({ isLoading: false }))
    );
  }


  fetchFeedItems = () => {
    return Axios.get(
      `/api/social/feed_items`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const feedItems = response.data.feed_items.map(
        f => FeedItemModel.fromJSON(f)
      );

      this.setState({ feedItems });

      return feedItems;
    });
  }


  fetchCompanyFeedItems = feedItemIds => {
    if( !this.props.currentUserRole.isCompanyRole() ){
      return;
    }

    return Axios.get(
      `/api/social/company_feed_items/companies/${ this.props.currentUserRole.roleTypeId }/distribution`,
      {
        headers: this.props.getUserRoleAuthHeaders(),
        params: {
          feed_item_ids: feedItemIds
        }
      }
    ).then(response => {
      const companyFeedItems = response.data.distribution.map(
        f => CompanyFeedItemModel.fromJSON(f)
      );

      this.setState({ companyFeedItems });

      return companyFeedItems;
    })
  }


  timelineContent = feedItem => {
    const Preview = FeedItemPreview(feedItem.feedType);

    return <Preview
      text={feedItem.feedContent.text}
      url={feedItem.feedContent.url}
      meta={feedItem.feedContent.linkMeta}
      attachments={feedItem.feedContent.attachments}
      feedType={feedItem.feedContent.feedType}
    />
  }

  // onDeleteItem = feedItem => {
  //   if( window.confirm('Are you sure you want to delete this Feed Item? This action cannot be undone.') ){
  //     return Axios.delete(
  //       `/api/social/feed_items/${feedItem.id}`,
  //       { headers: this.props.getUserRoleAuthHeaders() }
  //     ).then(response => {
  //       history.push(`/social/feeds/${feedItem.feedId}`)
  //     })
  //   }
  // }


  onPostItem = feedItem => {
    if( this.props.currentUserRole.isCompanyRole() ){
      return
    }
  }


  isFeedItemPosted = feedItem => {
    if( !this.props.currentUserRole.isCompanyRole() ){
      return feedItem && feedItem.isPosted;
    }

    const companyFeedItem = this.state.companyFeedItems.find(
      c => +c.feedItemId === +feedItem.id
    );

    return companyFeedItem && companyFeedItem.apiFeedId
  }


  render() {
    return (
      <SocialTimeline
        items={this.state.feedItems}
        timelineContent={this.timelineContent}
        fetchViewLink={feedItem => `/social/feed_items/${feedItem.id}/view`}
        showEditButton={false}
        showDeleteButton={false}
        showPostButton={false}
        isLoading={this.state.isLoading}
        showViewButtonForItem={this.isFeedItemPosted}
        isFeedItemPosted={this.isFeedItemPosted}
      />
    );
  }

}

export default withContext(
  UserRoleContext,
  FeedTimeline
)