import React from 'react';

import { Ability, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { withStyles, Grid } from '@material-ui/core';

import CompanyInvitationTable from 'Settings/Organizations/CompanyInvitations/CompanyInvitationTable/CompanyInvitationTable';
import CompanyTable from '../CompanyTable/CompanyTable';

class CompanyIndex extends React.Component {

  render() {
    const { organization, classes } = this.props;


    const showViewAllCompanies = (
      (
        this.props.organization &&
        this.props.organization.isGoBoost() &&
        this.props.currentUserRole.roleHasAbility(
          Ability.ORPHAN_COMPANY
        )
      ) ?
        true
      :
        false
    );

    return (
      <div className={classes.root}>
        <Grid
          spacing={16}
          container
        >
          <Grid item xs={12}>
            <CompanyTable
              organization={organization}
              showDescendantsInfo={true}
              showViewAllCompanies={showViewAllCompanies}
            />
          </Grid>

          <Grid item xs={12}>
            <CompanyInvitationTable
              organization={organization}
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

}


const styles= theme => ({
  root: {
    padding: 16,
  }
});

export default withStyles(styles)(withContext(OrganizationRoleContext, CompanyIndex));