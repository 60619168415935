import React from 'react';

import { withContext, withProvider } from 'kn-react';
import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';

import ProductCompletionPage from 'SharedSetup/ProductCompletionPage/ProductCompletionPage';

import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import CompanyReviewsProfileProvider, { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import CompanySitesProfileProvider, { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import CompanySocialProfileProvider, { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';

import adsNextSteps from './AdsNextSteps';


class AdsSetupCompletion extends React.Component {
  state = {
    nextSteps: []
  }

  componentDidMount = () => {
    this.setState({
      nextSteps: adsNextSteps(this.props)
    })
  }

  render(){
    const { classes } = this.props;


    return(
      <ProductCompletionPage
        product={'Ads'}
        nextSteps={this.state.nextSteps}
        dashboardLink={'/ads'}
      />
    )
  }
}

export default withProvider(
  CompanyReviewsProfileProvider,
  CompanySitesProfileProvider,
  CompanySocialProfileProvider,
  withContext(
    CompanyReviewsProfileContext,
    CompanySitesProfileContext,
    CompanySocialProfileContext,
    FacebookAccountContext,
    CompanyRoleContext,
    PlanContext,
    AdsSetupCompletion
  )
)