import React from 'react';

import { PlanContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { withStyles, Paper, Grid, Typography } from '@material-ui/core';

import { CompanySocialProfileContext } from 'Social/CompanySocial/CompanySocialProfile/CompanySocialProfileProvider';
import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import BoostedServicesProvider, { BoostedServicesContext } from 'Ads/CompanyAds/BoostedServices/BoostedServicesProvider';
import ChannelProvider, { ChannelContext } from 'Channels/ChannelProvider';
import CompanyAdsProfileProvider, { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import FacebookAccountCard from 'FacebookAccount/FacebookAccountCard';



const socialSettings = props => {
  let canSignOut = true;

  if( props.isSubscribedToAds && props.companyAdsProfile && !props.companyAdsProfile.isShutdown ){
    props.companyBoostedServices.forEach(c => {
      const boostedService = props.boostedServices.find(
        b => b.id === c.boostedServiceId
      );

      const channel = props.channels.find(
        ch => ch.id === boostedService.channelId
      );

      if( channel.isFacebook() ){
        canSignOut = false;
      }
    })
  }

  const isShutdown = props.companySocialProfile && props.companySocialProfile.isShutdown;

  return (
    <Paper className={props.classes.paperRoot}>
      <Grid container spacing={16}>
        <Grid item xs={12}>

          <Typography variant="subtitle1" color="primary">Connect Accounts</Typography>

          <Grid container justify='center'>
            <Grid item xs={12} md={6}>
              <FacebookAccountCard
                onUpdateAccount={props.onUpdateAccount}
                shouldEnqueueReviewsImporter={false}
                shouldConnectBusinessManager={false}
                canSignOut={canSignOut}
                isProductShutdown={isShutdown}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const styles = theme => ({
  paperRoot: {
    padding: 16
  },
  borderRight: {
    borderRight: '1px solid #efefef',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    }
  }
})


export default withProvider(
  CompanyAdsProfileProvider,
  BoostedServicesProvider,
  ChannelProvider,
  withContext(
    CompanyAdsProfileContext,
    BoostedServicesContext,
    PlanContext,
    CompanySocialProfileContext,
    FacebookAccountContext,
    ChannelContext,
    withStyles(styles)(socialSettings)
  )
)