import React from 'react';

import { PromiseButton, ToggleMapModel, Ability, CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Button, Grid, LinearProgress, Typography, withStyles } from '@material-ui/core';

import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';
import paperPadding from 'paperPadding';
import ToggleMapForm from 'Ads/CompanyAds/BoostedServices/BoostZone/ToggleMapForm/ToggleMapForm';

class BoostZoneSetup extends React.Component {
  state = {
    loading: true,
    boostZone: new ToggleMapModel(),
    basedOnServiceArea: false,
    boostedService: {},
    companyBoostedService: {},
  }

  componentDidMount = () => {
    const boostedServiceId = parseInt(this.props.match.params.id);
    const { boostedServices, companyBoostedServices, company } = this.props;
    const boostedService = boostedServices.find(b => b.id === boostedServiceId)
    const companyBoostedService = companyBoostedServices.find(c => c.boostedServiceId === boostedServiceId);

    let boostZone;
    let basedOnServiceArea = false;;
    if (companyBoostedService.boostZone.includedGeographies.length) {
      boostZone = companyBoostedService.boostZone;
    }
    else {
      boostZone = company.serviceArea;
      basedOnServiceArea = true;
    }

    let newState = {
      boostedService,
      companyBoostedService,
      loading: false,
    };

    if (boostZone) {
      newState = {
        ...newState,
        basedOnServiceArea,
        boostZone,
      };
    }
    this.setState(newState);
  }

  onToggleMapChange = toggleMapModel => {
    this.setState({ boostZone: toggleMapModel });
  }

  onClickBack = () => {
    const { companyBoostedService } = this.state;
    return this.props.transitionTo('back', this.props.transitions, { boostedServiceId: companyBoostedService.boostedServiceId });
  }

  onClickNext = () => {
    const { boostZone, companyBoostedService } = this.state;

    return this.props.updateCompanyBoostedService(companyBoostedService.id, { boostZone })
      .then(() => this.props.transitionTo('next', this.props.transitions, { boostedServiceId: companyBoostedService.boostedServiceId }));
  }

  render() {
    const { classes, company } = this.props;
    const { boostZone, basedOnServiceArea, boostedService, companyBoostedService, loading } = this.state;

    if (loading) {
      return <LinearProgress/>;
    }

    const canEdit = this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_BILLING );

    const isShutdown = (
      this.props.companyAdsProfile &&
      this.props.companyAdsProfile.isSetupComplete &&
      this.props.companyAdsProfile.isShutdown
    );

    return (
      <div className={classes.root}>
        <div className={classes.adsUndoResubscriptionAlert}>
          <AdsUndoResubscriptionAlert />
        </div>

        <Typography variant="h5">Boost Zone</Typography>
        <Typography variant="caption">This is the area where your <span className={classes.colorPrimary}>{boostedService.name}</span> ads will be shown. {basedOnServiceArea ? `Below is your company service area. Please make any necessary adjustments.` : null}</Typography>

        <ToggleMapForm
          toggleMapModel={boostZone}
          onToggleMapChange={this.onToggleMapChange}
          latitude={company.latitude}
          longitude={company.longitude}
          canEdit={canEdit}
        >
          <Grid container>
            <Grid item xs={6}>
              <Button color="primary" variant="outlined" onClick={this.onClickBack}>Back</Button>
            </Grid>

            {
              canEdit ?
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <PromiseButton
                      buttonProps={{
                        variant: 'contained',
                        color: 'primary',
                        fullWidth: false,
                        disabled: isShutdown || boostZone.includedGeographies.length < 1
                      }}
                      onProcess={
                        isShutdown ?
                        () => Promise.resolve()
                        :
                          this.onClickNext
                      }
                    >
                      {companyBoostedService.isConfigured() ? 'Save' : 'Next'}
                    </PromiseButton>
                  </Grid>
                :
                  null
            }

          </Grid>
        </ToggleMapForm>
      </div>
    );
  }

}

const styles = theme => ({
  root: {
    padding: paperPadding,
  },
  colorPrimary: {
    color: theme.palette.primary.main
  },
  adsUndoResubscriptionAlert: () => null
});


export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    TransitionContext,
    BoostZoneSetup
  )
);
