/*global analytics*/
import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import CheckoutSetup from './CheckoutSetup/CheckoutSetup';
import GoogleAndFacebookAccountSetup from 'SharedSetup/GoogleAndFacebookAccountSetup';
import ReviewsPlanSetup from './ReviewsPlanSetup/ReviewsPlanSetup';
import ReviewsSetupCompletion from './ReviewsSetupCompletion/ReviewsSetupCompletion';
import ReviewTrackersLocationProvider, { ReviewTrackersLocationContext } from 'Reviews/CompanyReviews/ReviewTrackersLocation/ReviewTrackersLocationProvider';
import ReviewTrackersLocationSetup from './ReviewTrackersLocationSetup/ReviewTrackersLocationSetup';
import TransitionProvider from 'TransitionProvider/TransitionProvider';

class ReviewsSetupProcess extends React.Component {
  componentDidMount = () => {
    if(!this.props.companyReviewsProfile) {
      this.props.createCompanyReviewsProfile();
    }
  }

  render() {
    return (
      this.props.companyReviewsProfile ?
        <WrappedTransitionProvider initialStatePath="/reviews/setup" machineStates={machineStates} />
      :
        null
    );
  }
}


export default withContext(CompanyReviewsProfileContext, ReviewsSetupProcess);


// 1) Wrap the Transition Provider with the Providers it will need to evaluate transitions
const WrappedTransitionProvider = withProvider(
  ReviewTrackersLocationProvider,
  withContext(
    ReviewTrackersLocationContext,
    CompanyReviewsProfileContext,
    PlanContext,
    CompanyRoleContext,
    TransitionProvider
  )
);

// 2) Define the steps of the process, each with the component, a path, optionally `exact` for the path, and a transitions object. At minimum, the transitions object should define a 'next' key, which is a function of props that returns an object of the form `{ path: String, params: Object?, query: Object? } `
// back: { path: '/reviews/setup/notification_preferences' },
// next: props => (
//   { path: '/reviews/setup/payment_method', query: { selected_plan_id: props.selectedPlanId } }
// ),


const sharedState = {
  selectedPlanId: null
};


const guardSelectedPlanId = props => (
  !sharedState.selectedPlanId ? '/reviews/setup/plans' : null
);

const isReviewTrackersIncluded = props => {
  const p = props.upgradableReviewsPlans.find(p => p.id === sharedState.selectedPlanId);
  return p && p.isReviewTrackersIncluded;
}

const guardReviewTrackersName = props => {
  return isReviewTrackersIncluded(props) && !props.reviewTrackersLocation.name ? '/reviews/setup/reviews_settings' : null
}


const machineStates = [
  // Select a Plan
  {
    component: props => <ReviewsPlanSetup {...props} selectedPlanId={sharedState.selectedPlanId} />,
    path: '/reviews/setup/plans',
    guard: props => {
      if( !props.isReviewsAvailable ){
        return '/'
      }else if( props.companyReviewsProfile.isSetupComplete ){
        return '/reviews';
      }

      trackCurrentUserSegmentEvent(
        props.currentUserRole.userId,
        'started_reviews_setup',
        props.getUserRoleAuthHeaders,
        { funnel: true }
      );

      if (props.upgradableReviewsPlans.length === 1) {
        sharedState.selectedPlanId = sharedState.selectedPlanId || props.upgradableReviewsPlans[0].id;
        return '/reviews/setup/google_and_facebook';
      }

      return null;
    },
    transitions: {
      next: props => {
        sharedState.selectedPlanId = props.selectedPlanId;
        return '/reviews/setup/google_and_facebook';
      }
    }
  },
  // Link Google and Facebook
  {
    path: '/reviews/setup/google_and_facebook',
    component: props => <GoogleAndFacebookAccountSetup {...props} showBack={props.upgradableReviewsPlans.length > 1} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/reviews/setup/plans',
      next: '/reviews/setup/reviews_settings',
    }
  },
  // Setup Review Trackers Location if included in plan
  {
    // TODO Send them to reviews_settings only if they have review trackers, otherwise this is unnecessary
    path: '/reviews/setup/reviews_settings',
    component: ReviewTrackersLocationSetup,
    guard: props => {
      if (!sharedState.selectedPlanId) return '/review/setup/plans';
      return isReviewTrackersIncluded(props) ? null : 'continue';
    },
    transitions: {
      back: '/reviews/setup/google_and_facebook',
      next: '/reviews/setup/checkout',
    }
  },
  // // Review Request Settings
  // {
  //   path: '/reviews/setup/review_request',
  //   component: ReviewRequestSettingsSetup,
  //   guard: props => guardSelectedPlanId(props) || guardReviewTrackersName(props),
  //   transitions: {
  //     back: '/reviews/setup/reviews_settings',
  //     next: '/reviews/setup/notification_preferences',
  //   }
  // },
// },
  {
    path: '/reviews/setup/checkout',
    component: props => <CheckoutSetup {...props} selectedPlanId={sharedState.selectedPlanId} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/reviews/setup/reviews_settings',
      next: '/reviews/setup/done',
    }
  },
  // Reviews Completions Page
  {
    path: '/reviews/setup/done',
    component: props => <ReviewsSetupCompletion {...props} />,
    guard: guardSelectedPlanId,
    transitions: {
      back: '/reviews',
      next: '/reviews',
    }
  },
];
