import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Material
import DashboardIcon from '@material-ui/icons/Dashboard';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PermMediaIcon from '@material-ui/icons/PermMedia';
// Kinertia
import { SubNavigation, ReviewsStarIcon } from 'go-boost-library-react';
import { withProvider, log } from 'kn-react';
// App
import AppNavigation from '../../AppNavigation/AppNavigation';
import SocialDashboard from './SocialDashboard';
import Feed from './Feed/Feed';
import Timeline from 'Social/Timeline/Timeline';
import FeedTimeline from './Feed/FeedTimeline';
import NewFeedItem from 'Social/FeedItem/NewFeedItem';
import EditFeedItem from 'Social/FeedItem/EditFeedItem';
import ViewFeedItem from 'Social/FeedItem/ViewFeedItem';
import MediaItems from 'Social/MediaItems/MediaItems';
import NewMediaItem from 'Social/MediaItems/NewMediaItem';
import EditMediaItems from 'Social/MediaItems/EditMediaItems';


class OrganizationSocial extends React.Component {

  render() {
    var subNavLinks = [
      { primaryText: 'Dashboard', path: '/social', exactPathMatch: true, icon: <DashboardIcon /> },
      { primaryText: 'Feeds', path: '/social/feeds', icon: <CalendarViewDayIcon />, isActive: () => /\/social\/feeds|\/social\/feed_items/.test(window.location.pathname) },
      { primaryText: 'Timeline', path: '/social/timeline', icon: <CalendarIcon /> },
      { primaryText: 'Media Items', path: '/social/media_items', icon: <PermMediaIcon /> },
    ];


    return (
      <AppNavigation title="Social">
        <SubNavigation links={subNavLinks}>
          <Switch>
            <Route exact path="/social" component={SocialDashboard} />
            <Route exact path="/social/feeds" component={Feed} />
            <Route path="/social/feeds/:feedId/timeline" component={FeedTimeline} />
            <Route path="/social/feeds/:feedId/new" component={NewFeedItem} />
            <Route path="/social/feed_items/:feedItemId/edit" component={EditFeedItem} />
            <Route path="/social/feed_items/:feedItemId/view" component={ViewFeedItem} />
            <Route exact path="/social/timeline" component={Timeline} />
            <Route exact path="/social/media_items" component={MediaItems} />
            <Route exact path="/social/media_items/new" component={NewMediaItem} />
            <Route exact path="/social/media_items/:id/edit" component={EditMediaItems} />
            <Route path="*" component={props => <Redirect to='/'/>} />
          </Switch>
        </SubNavigation>
      </AppNavigation>
    );
  }

}

export default OrganizationSocial