import React from "react";
import Axios from 'axios';

import { withContext } from "kn-react";
import { PromiseButton, SnackbarContext, UserRoleContext } from 'go-boost-library-react';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, List, Typography, ListItem, ListItemText, withStyles } from '@material-ui/core';
import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';



class MeetingRoomInvitation extends React.Component {
  state = {
    phoneNumber: '',
    email: '',
    canParseEmail: false,
    canParsePhoneNumber: false
  }


  componentDidUpdate = oldProps => {
    if( this.props.open && oldProps.open !== this.props.open ){
      return this.setState(
        { phoneNumber: '', email: '' },
        this.fetchCanParseFromClipboard
      );
    }
  }


  onSend = () => {
    const { email, phoneNumber } = this.state;

    if( !( email || phoneNumber ) ){
      this.props.showSnackbar('Email or phone number is required.');
      return Promise.resolve();
    }


    if(
      phoneNumber &&
      ( phoneNumber.length !== 10 || !+phoneNumber )
    ){
      this.props.showSnackbar('Phone numbers can only be 10 digits long.');
      return Promise.resolve();
    }


    return Axios.post(
      `/api/virtual/meeting_room_invitations/meeting_rooms/${ this.props.meetingRoom.id }`,
      {
        email: this.state.email,
        phone_number: this.state.phoneNumber
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(() => {
      this.props.showSnackbar('Meeting Room Invitation successfully sent.');
      this.props.onClose();
    })
  }


  fetchCanParseFromClipboard = () => {
    return navigator.clipboard.readText().then(
      text => {
        let canParseEmail = false;
        let canParsePhoneNumber = false;


        if( text.length === 10 && +text ){
          canParsePhoneNumber = true;
        }

        if( text.match(/\d{10}; .+@.+/) ){
          canParseEmail = true;
          canParsePhoneNumber = true;
        }

        if( text.includes( '@' ) ){
          canParseEmail = true;
        }


        return this.setState({ canParseEmail, canParsePhoneNumber });
      }
    );
  }



  parseFromClipboard = () => {
    return navigator.clipboard.readText().then(
      text => {
        let { email, phoneNumber, canParseEmail, canParsePhoneNumber } = this.state;

        if( !text || !( canParseEmail || canParsePhoneNumber ) ){
          return;
        }

        if( canParseEmail && !canParsePhoneNumber ){
          email = text;
        } else if( canParsePhoneNumber && !canParseEmail ){
          phoneNumber = text;
        } else {
          const numberAndEmail = text.match(/(\d{10});\s(.+@.+)/);

          phoneNumber = numberAndEmail[ 1 ];
          email = numberAndEmail[ 2 ];
        }


        return this.setState({ email, phoneNumber });
      }
    );
  }


  render(){
    const { classes } = this.props;

    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        size='sm'
        fullWidth
      >
        <DialogTitle>
          Meeting Room Invitation
        </DialogTitle>

        <DialogContent>
          {
            !( this.state.canParseEmail || this.state.canParsePhoneNumber ) ?
              null
            :
              <Button
                variant='contained'
                color='secondary'
                onClick={this.parseFromClipboard}
                size='small'
                className={classes.parseButton}
              >
                Parse { this.state.canParseEmail && !this.state.canParsePhoneNumber ? 'Email' : !this.state.canParseEmail && this.state.canParsePhoneNumber ? 'Phone Number' : 'Phone Number & Email' } From Clipboard
              </Button>
          }
          <TextField
            fullWidth
            label='Phone Number'
            variant='outlined'
            value={this.state.phoneNumber}
            onChange={e => this.setState({ phoneNumber: e.target.value })}
            className={classes.textField}
          />

          <TextField
            fullWidth
            label='Email'
            variant='outlined'
            value={this.state.email}
            onChange={e => this.setState({ email: e.target.value })}
            className={classes.textField}
          />

          <List>
            <Typography variant="body1">
              Preview
            </Typography>

            <ListItem
              className={classes.listItem}
            >
              <ListItemText
                primary={
                  <>
                    <Typography variant="body1">
                      { this.props.companyVirtualProfile.meetingRoomInvitationDefaultMessage }
                      <a href={this.props.meetingRoom.url}>{ this.props.meetingRoom.url }</a>
                    </Typography>
                  </>
                }
              />
            </ListItem>
          </List>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={this.props.onClose}
            color='primary'
            variant='outlined'
          >
            Close
          </Button>

          <PromiseButton
            onProcess={this.onSend}
            buttonProps={{
              color: 'primary',
              variant: 'contained',
            }}
          >
            Send
          </PromiseButton>
        </DialogActions>

        <div className={classes.legal}>
          <Typography variant="caption">
            By clicking "SEND" you acknowledge that you received this person's consent to send them this invite.
          </Typography>
        </div>
      </Dialog>
    )
  }
}


MeetingRoomInvitation.defaultProps = {
  open: false,
  onClose: () => {},
  meetingRoom: ''
}


const styles = theme => ({
  textField: {
    marginBottom: 10,
    marginTop: 10,
  },
  listItem: {
    marginTop: 15
  },
  parseButton: {
    float: 'right'
  },
  legal: {
    paddingLeft: 15,
    paddingBottom: 5,
    paddingTop: 15
  }
})


export default withContext(
  CompanyVirtualProfileContext,
  UserRoleContext,
  SnackbarContext,
  withStyles(styles)(
    MeetingRoomInvitation
  )
)