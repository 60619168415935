import React from 'react';

import { Alert, PlanContext } from 'go-boost-library-react';
import { withContext, withProvider } from 'kn-react';

import { Paper, Grid, withStyles, Typography } from '@material-ui/core';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import BoostedServicesProvider, { BoostedServicesContext } from 'Ads/CompanyAds/BoostedServices/BoostedServicesProvider';
import ChannelProvider, { ChannelContext } from 'Channels/ChannelProvider';
import CompanyAdsProfileProvider, { CompanyAdsProfileContext } from 'Ads/CompanyAds/CompanyAdsProfile/CompanyAdsProfileProvider';
import FacebookAccountCard from 'FacebookAccount/FacebookAccountCard';
import GoogleAccountCard from 'GoogleAccount/GoogleAccountCard';


const reviewsConnectedAccounts = props => {
  let canSignOut = true;

  if( props.isSubscribedToAds && props.companyAdsProfile && !props.companyAdsProfile.isShutdown ){
    props.companyBoostedServices.forEach(c => {
			const boostedService = props.boostedServices.find(
				b => b.id === c.boostedServiceId
			);

      const channel = props.channels.find(
				ch => ch.id === boostedService.channelId
			);

			if( channel.isFacebook() ){
				canSignOut = false;
			}
		})
  }

  const isShutdown = (
    props.companyReviewsProfile &&
    props.companyReviewsProfile.isShutdown &&
    props.companyReviewsProfile.isSetupComplete
  );

  return (
    <Paper className={props.classes.paperRoot}>
      <Grid container spacing={16}>
        <Grid item xs={12}>

          { props.children }

          <Grid container justify='center'>
            <Grid item xs={12} md={6} className={props.classes.borderRight}>
              <GoogleAccountCard
                onUpdateAccount={props.onUpdateAccount}
                canSignIn={!isShutdown}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FacebookAccountCard
                onUpdateAccount={props.onUpdateAccount}
                shouldEnqueueReviewsImporter={true}
                shouldConnectBusinessManager={false}
                isProductShutdown={isShutdown}
                canSignOut={canSignOut}
              >
                {
                  (
                    props.facebookAccountPage &&
                    props.facebookAccountPage.id &&
                    !isShutdown
                  ) ?
                    <Alert bootstrapPrimary>
                      <Typography
                        variant="body1"
                        style={{ textAlign: 'left' }}
                      >
                        Be sure to manually allow Reviews on your Facebook Page.&nbsp;
                        <a
                          href="https://support.goboost.com/en/support/solutions/articles/1000308746-how-to-turn-on-facebook-reviews"
                          target="_blank"
                        >
                          Click here to learn more.
                        </a>
                      </Typography>
                    </Alert>
                  :
                    null
                }
              </FacebookAccountCard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
)
};

const styles = theme => ({
  paperRoot: {
    padding: 16
  },
  borderRight: {
    borderRight: '1px solid #efefef',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
    }
  }
});


reviewsConnectedAccounts.defaultProps = {
  onUpdateAccount: (account, callback) => {}
}


export default withProvider(
  CompanyAdsProfileProvider,
  BoostedServicesProvider,
  ChannelProvider,
  withContext(
    CompanyAdsProfileContext,
    CompanyReviewsProfileContext,
    FacebookAccountContext,
    BoostedServicesContext,
    PlanContext,
    ChannelContext,
    withStyles(styles)(
      reviewsConnectedAccounts
    )
  )
);
