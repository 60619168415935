import React from 'react';
import { Link } from 'react-router-dom';

import { PlanContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { withStyles, Typography } from '@material-ui/core';

import ListSubscribedPlans from './listSubscribedPlans';




class SubscriptionList extends React.Component {

  state = {

  }

  render() {
    const { subscribedPlans, classes, subscribedAnnualDomainPlan } = this.props;

    return (
      !subscribedPlans.length ?
        <>
          <Typography color="textSecondary" variant="caption" className={classes.noSubscriptions}>
            You are currently not subscribed to any plans. <Link to="/">Click here</Link> to view available products.
          </Typography>
        </>
      :
        <ListSubscribedPlans
          subscribedPlans={subscribedPlans}
          subscribedAnnualDomainPlan={subscribedAnnualDomainPlan}
        />
    );
  }

}


const styles = theme => ({
  listItemRoot: {
    backgroundColor: '#FAFAFA',
    border: '1px solid #F5F5F5',
    borderRadius: 5,
    padding: 20,
  },
  priceRoot: {
    textAlign: 'right'
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: 32,
  },
  period: {
    color: '#7f8788',
    fontSize: 16,
  },
  noSubscriptions: {
    textAlign: 'left',
    padding: 25,
  }
});

export default withStyles(styles)(
  withContext(
    PlanContext,
    SubscriptionList
  )
);