import React from 'react';
import Axios from 'axios';
import { isDevelopment, isStaging, isDemo } from 'go-boost-library-react';
import hashIdCoder from 'hashIdCoder';

import sitePreviewUrl from 'Sites/sitePreviewUrl';



const fetchSitesNextSteps = props => {
  const nextSteps = [ ...steps ];

  return Axios.get(
    `/api/sites/company_site_designs/${props.companySitesProfile.companySiteDesignId}`,
    { headers: props.getUserRoleAuthHeaders() }
  ).then(response => Axios.get(
    `/api/sites/${response.data.company_site_design.site_id}`,
    { headers: props.getUserRoleAuthHeaders() }
  )).then(response => {
    if( response.data.site.published_at ){
      nextSteps[0].checked=true;
    }

    nextSteps[0].link = sitePreviewUrl(
      response.data.site
    );


    if( props.companySitesProfile && props.companySitesProfile.logo_url ){
      nextSteps=[1].checked = true;
    }

    return nextSteps
  })
}



fetchSitesNextSteps.defaultProps = {
  googleAccount: {},
  company: {},
  getUserRoleAuthHeaders: () => {},
  currentUserRole: {}
}



export default fetchSitesNextSteps



export const steps = [
  {
    text: 'Visit your site and send us feed to help it go live.',
    checked: false,
    link: ''
  },
  {
    text: 'Upload your company logo.',
    checked: false,
    link: '/sites/settings#logo'
  }
]