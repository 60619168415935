import React from 'react';

import { FacebookPageOauth, FacebookTextLogo, SnackbarContext, Alert, PromiseButton, UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { HelpOutline } from '@material-ui/icons';
import { withStyles, List, ListItem, Typography, Tooltip, IconButton, Button } from '@material-ui/core';

import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import { FacebookAccountContext } from 'FacebookAccount/FacebookAccountProvider';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import paperPadding from 'paperPadding';
import supportPortalLink from 'AppNavigation/supportPortalLink';


class FacebookAccountCard extends React.Component {
  componentDidMount = () => {
    if( this.props.facebookClientId ){
      return this.props.fetchFacebookAccount()
        .then(this.props.fetchFacebookAccountPage);
    }
  }

  hasEditPermission = () => {
    const { currentUserRole } = this.props;

    return currentUserRole.roleHasAbility( Ability.EDIT_FACEBOOK_ACCOUNT );
  }

  putFacebookAccountPage = (page) => {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'connected_facebook', this.props.getUserRoleAuthHeaders);

    const { shouldConnectBusinessManager, shouldEnqueueReviewsImporter } = this.props;

    return this.props.putFacebookAccountPage(page, { shouldConnectBusinessManager })
      .then(page => shouldEnqueueReviewsImporter ? this.props.findOrCreateFacebookReviewSite() : null)
      .then(reviewSite => shouldEnqueueReviewsImporter ? this.props.enqueueFacebookImporter() : null)
      .then(response => this.props.onUpdateAccount())
      .then(this.props.fetchFacebookAccountPage)
      .then(() => this.props.showSnackbar('Facebook Account and Page successfully setup.'))
      .catch(error => log(error));
  }

  onSignOut = () => {
    return this.props.facebookSignOut()
      .then(response => this.props.onUpdateAccount());
  }


  render() {
    const { classes, canSignIn, canSignOut, isProductShutdown } = this.props;

    const LearnMore = () => (
      <Tooltip title='Learn More'>
        <IconButton
          component={'a'}
          href={supportPortalLink}
          target="_blank"
        >
          <HelpOutline />
        </IconButton>
      </Tooltip>
    );

    return (
      <div className={classes.paperRoot}>
        <List>
          <FacebookTextLogo width={'60%'} />

          <ListItem>
            <div className={classes.authPage}>
              <FacebookPageOauth
                clientId={this.props.facebookClientId}
                facebookRedirectUrl='/auth/facebook/callback'
                getFacebookPagesCallback={this.props.getFacebookPagesCallback}
                onAuthPageComplete={this.putFacebookAccountPage}
                onCode={this.props.onCode}
                facebookAccount={this.props.facebookAccount}
                facebookAccountPage={this.props.facebookAccountPage}
                signOut={this.onSignOut}
                canSignIn={canSignIn === false || isProductShutdown ? false : canSignIn || this.hasEditPermission()}
                canSignOut={canSignOut === false ? canSignOut : canSignOut || this.hasEditPermission()}
                facebookAccountPageLoading={this.props.facebookAccountPageLoading}
              />
            </div>
          </ListItem>

          {
            (
              this.props.facebookAccountPage &&
              this.props.facebookAccountPage.id &&
              this.props.shouldConnectBusinessManager &&
              !this.props.facebookAccountPage.isBusinessManagerConnected &&
              !isProductShutdown
            ) ?
              <Alert warning>
                {
                  this.props.facebookAccountPage.shouldConnectBusinessManager ?
                    <>
                      <div style={{ float: 'right' }}>
                        <Button
                          component={'a'}
                          variant={'contained'}
                          color={'primary'}
                          href={`https://facebook.com/${ this.props.facebookAccountPage.apiPageId }/settings/?tab=admin_roles`}
                          target={'_blank'}
                        >
                          Go To Page's Settings
                        </Button>
                      </div>

                      <Typography
                        variant="body1"
                        className={classes.connectToBusinessManager}
                      >
                        Next, please accept GoBoost's Agency request in the 'Page Roles' section of your Facebook Page's settings.&nbsp;

                        <LearnMore />
                      </Typography>
                    </>
                  :
                    <>
                      <div style={{ float: 'right' }}>
                        <PromiseButton
                          onProcess={this.props.connectToBusinessManager}
                          buttonProps={{
                            variant: 'contained',
                            color: 'primary'
                          }}
                        >
                          Start setup
                        </PromiseButton>
                      </div>

                      <Typography
                        variant="body1"
                        className={classes.connectToBusinessManager}
                      >
                        You've connected Facebook, but there is one more step to run ads.&nbsp;

                        <LearnMore />
                      </Typography>
                    </>
                }

              </Alert>
            :
              null
          }

          {
            this.props.children ?
              this.props.children
            :
              null
          }
        </List>
      </div>
    );
  }

}


FacebookAccountCard.defaultProps = {
  onUpdateAccount: () => {},
  shouldEnqueueReviewsImporter: false,
  shouldConnectBusinessManager: false,
  canSignOut: true,
  canSignIn: true,
  isProductShutdown: false
};

const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
    textAlign: 'center'
  },
  avatar: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 10
  },
  authPage: {
    textAlign: 'center',
    width: '100%'
  },
  connectToBusinessManager: {
    lineHeight: '36px',
    textAlign: 'left'
  },
  agencyRequest: {
    textAlign: 'left'
  }
})


export default withContext(
  UserRoleContext,
  SnackbarContext,
  FacebookAccountContext,
  CompanyReviewsProfileContext,
  withStyles(styles)(FacebookAccountCard)
);
