import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { SubNavigation, Ability, FacebookIcon, OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import BuildIcon from '@material-ui/icons/Build';
import CallIcon from '@material-ui/icons/Call';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/Dashboard';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';

import AdsDashboard from './AdsDashboard/AdsDashboard';
import AdsOpportunities from './AdsOpportunities/AdsOpportunities';
import AdwordsTemplates from './AdwordsTemplates/AdwordsTemplates';
import AppNavigation from '../../AppNavigation/AppNavigation';
import BoostedServices from './BoostedServices/BoostedServices';
import FacebookReports from './FacebookReports/FacebookReports';
import FacebookTemplates from './FacebookTemplates/FacebookTemplates';
import SearchQueries from './SearchQueries/SearchQueries';


class OrganizationAds extends React.Component {
  render(){
    const { currentUserRole } = this.props;

    let subNavLinks = [
      { primaryText: 'Dashboard', path: '/ads', exactPathMatch: true, icon: <DashboardIcon/> },
      { primaryText: 'Opportunities', path: '/ads/opportunities', icon: <CallIcon/> },
      { primaryText: 'Boosted Services', path: '/ads/boosted_services', icon: <BuildIcon/> },
    ];
    const routes = [
      <Route key="ads" path="/ads" exact component={ AdsDashboard }/>,
      <Route key="opportunities" path="/ads/opportunities" component={ AdsOpportunities }/>,
      <Route key="boosted_services" path="/ads/boosted_services" component={ BoostedServices }/>
    ];

    if (currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_ADS_SEARCH_QUERIES)) {
    subNavLinks.push(
        { primaryText: 'Search Queries', path: '/ads/search_queries', icon: <YoutubeSearchedForIcon/>}
      );
      routes.push(<Route key="search_queries" path="/ads/search_queries" component={ SearchQueries }/>)
    }

    if (currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_ADS_TEMPLATES)) {
      subNavLinks = [...subNavLinks, { primaryText: 'Adwords Templates', path: '/ads/adwords_templates', icon: <CodeIcon/> }];
      routes.push(<Route key="adwords_templates" path="/ads/adwords_templates" component={ AdwordsTemplates }/>)


      subNavLinks = [...subNavLinks, { primaryText: 'Facebook Templates', path: '/ads/facebook_templates', icon: <CodeIcon/> }];
      routes.push(<Route key="facebook_templates" path="/ads/facebook_templates" component={ FacebookTemplates }/>)
    }

    // if (currentUserRole.roleHasAtLeastOneAbility(Ability.VIEW_ADS_FACEBOOK_REPORTS)) {
    //   subNavLinks = [...subNavLinks, { primaryText: 'Facebook Reports', path: '/ads/facebook_reports', icon: <FacebookIcon/> }];
    //   routes.push(<Route key="facebook_reports" path="/ads/facebook_reports" component={ FacebookReports }/>)
    // }

    routes.push(<Route path="*" component={props => <Redirect to='/'/>} />)

    return (
      <AppNavigation title="Ads">
        <SubNavigation links={subNavLinks}>
          <Switch>
            { routes.map(r => r) }
          </Switch>
        </SubNavigation>
      </AppNavigation>
    );
  }

}

export default withContext(OrganizationRoleContext, OrganizationAds);