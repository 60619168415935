import React from 'react';
import Axios from 'axios';
import moment from 'moment';

import { TableToolbar, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, format } from 'kn-react';

import { Grid, Typography, Paper, IconButton, LinearProgress } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableFooter } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ChargeModel from './ChargeModel';
import InvoiceModel from './InvoiceModel';


class PaymentHistory extends React.Component {
  state = {
    loadingInvoices: true,
    invoicesPage: 0,
    invoicesTotal: 0,
    invoices: [],
    invoiceRowsPerPage: 10,
    invoiceRowsPerPageOptions: [10],

    loadingCharges: true,
    chargesPage: 0,
    chargesTotal: 0,
    charges: [],
    chargesRowsPerPage: 10,
    chargesRowsPerPageOptions: [10],
  }


  componentDidMount = () => {
    this.loadInvoices();
    this.loadCharges();
  }


  loadInvoices = (params = {}) => {
    log('loadInvoices');

    return Axios.get(
      `/api/core/billing/invoices`,
      {
        params,
        headers: this.props.getUserRoleAuthHeaders()
      }
    ).then(response => {
      log('loadInvoices response', response);
      const invoices = response.data.invoices.map(i => InvoiceModel.fromJSON(i));
      this.setState({
        invoices,
        loadingInvoices: false,
        invoicesTotal: response.data.total
      });
    })
  }


  changeInvoicesPage = (newPage) => {
    const { invoicesPage, invoices } = this.state;
    const params = {};

    if (newPage > invoicesPage) {
      params.starting_after = invoices.slice(-1)[0].id;
    } else {
      params.ending_before = invoices[0].id;
    }

    this.setState(
      { invoicesPage: newPage },
      () => this.loadInvoices(params)
    );
  }


  loadCharges = (params = {}) => {
    log('loadCharges');

    return Axios.get(
      `/api/core/billing/charges`,
      {
        params,
        headers: this.props.getUserRoleAuthHeaders()
      }
    ).then(response => {
      log('loadCharges response', response);
      const charges = response.data.charges.map(i => ChargeModel.fromJSON(i));
      this.setState({
        charges,
        loadingCharges: false,
        chargesTotal: response.data.total
      });
    })
  }

  changeChargesPage = (newPage) => {
    const { chargesPage, charges } = this.state;
    const params = {};

    if (newPage > chargesPage) {
      params.starting_after = charges.slice(-1)[0].id;
    } else {
      params.ending_before = charges[0].id;
    }

    this.setState(
      { chargesPage: newPage },
      () => this.loadCharges(params)
    );
  }


  render() {
    const { classes } = this.props;
    const { invoices, loadingInvoices, charges, loadingCharges } = this.state;

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          {/* Charges */}
          <Grid item xs={12}>
            <Paper className={classes.paperRoot}>
              <TableToolbar
                title="Charges"
                caption={
                  <>
                    <span>Charges and receipts to your account.</span>
                  </>
                }
              />
              { loadingCharges ? <LinearProgress color="primary"/> : null }
              {
                (!this.state.chargesTotal && !loadingCharges) ?
                  <Typography variant="caption" className={classes.noData}>No charges.</Typography>
                :
                <div className={classes.tableWrapper}>
                  <Table className={classes.table} style={{ opacity: loadingCharges ? 0.5 : 1 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Receipt</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {charges.map(charge => (
                        <TableRow key={charge.id}>
                          <TableCell align="right" component="th" scope="row">
                            {
                              charge.refunded ?
                              format.money(-charge.amountRefunded / 100)
                                :
                              format.money((charge.amount - charge.amountRefunded) / 100)
                            }
                          </TableCell>

                          <TableCell>
                            <Typography color="primary">
                              {
                                charge.amountRefunded ?
                                  'REFUNDED'
                                :
                                  charge.status.toUpperCase()
                              }
                            </Typography>
                          </TableCell>

                          <TableCell>
                            {charge.description}<br/>
                            <Typography variant="caption" color="textSecondary">
                              {charge.paymentMethodDetails.card.brand.toUpperCase()} - {charge.paymentMethodDetails.card.last4}
                            </Typography>
                          </TableCell>


                          <TableCell align="right">{moment((charge.created || 0) * 1000).format('YYYY-M-D, h:mm a')}</TableCell>

                          <TableCell align="right">
                            <IconButton component="a" size="small" href={charge.receiptUrl} target="_blank">
                              <OpenInNewIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter className={classes.tableFooter}>
                      <TableRow>
                        <TablePagination
                          count={this.state.chargesTotal || 0}
                          rowsPerPage={this.state.chargesRowsPerPage}
                          page={this.state.chargesPage}
                          onChangePage={(e, p) => this.changeChargesPage(p)}
                          rowsPerPageOptions={this.state.chargesRowsPerPageOptions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              }
            </Paper>
          </Grid>

          {/* Invoices */}
          <Grid item xs={12}>
            <Paper className={classes.paperRoot}>
              <TableToolbar
                title="Invoices"
                caption={
                  <>
                    <span>All paid and unpaid invoices</span>
                  </>
                }
              />
              { loadingInvoices ? <LinearProgress color="primary"/> : null }

              {
                (!this.state.invoicesTotal && !loadingInvoices) ?
                  <Typography variant="caption" className={classes.noData}>No invoices.</Typography>
                :
                <div className={classes.tableWrapper}>
                  <Table className={classes.table} style={{ opacity: loadingInvoices ? 0.5 : 1 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Invoice Number</TableCell>
                        <TableCell align="right">Payment Due</TableCell>
                        <TableCell align="right">Date</TableCell>
                        <TableCell align="right">Link</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoices.map(invoice => (
                        <TableRow key={invoice.id}>
                          <TableCell align="right" component="th" scope="row">
                            {format.money(invoice.amountDue / 100)}
                          </TableCell>

                          <TableCell align="right">
                            <Typography color="primary">
                              {invoice.status.toUpperCase()}
                            </Typography>
                          </TableCell>

                          <TableCell>{invoice.number}</TableCell>

                          <TableCell align="right">{invoice.amountRemaining > 0 ? format.money(invoice.amountRemaining / 100) : '-'}</TableCell>

                          <TableCell align="right">{moment((invoice.date || 0) * 1000).format('YYYY-M-D, h:mm a')}</TableCell>

                          <TableCell align="right">
                            <IconButton component="a" size="small" href={invoice.hostedInvoiceUrl} target="_blank">
                              <OpenInNewIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter className={classes.tableFooter}>
                      <TableRow>
                        <TablePagination
                          count={this.state.invoicesTotal || 0}
                          rowsPerPage={this.state.invoiceRowsPerPage}
                          page={this.state.invoicesPage}
                          onChangePage={(e, p) => this.changeInvoicesPage(p)}
                          rowsPerPageOptions={this.state.invoiceRowsPerPageOptions}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              }
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }

}


const styles = theme => ({
  root: {
    padding: 16
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: 500,
  },
  tableFooter: {
    textAlign: 'right',
    padding: 10,
  },
  noData: {
    textAlign: 'center',
    padding: 25,
    color: '#777',
  },
});

export default withStyles(styles)(withContext(CompanyRoleContext, PaymentHistory));