import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import { SubNavigation, SnackbarContext, UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import BuildIcon from '@material-ui/icons/Build';
import CallIcon from '@material-ui/icons/Call';
import DashboardIcon from '@material-ui/icons/Dashboard';
import MapIcon from '@material-ui/icons/Map';
import SettingsIcon from '@material-ui/icons/Settings';

import { CompanyAdsProfileContext } from './CompanyAdsProfile/CompanyAdsProfileProvider';
import AddToBalance from './BoostedServices/AddToBalance/AddToBalance';
import AdsCountryUnavailable from './AdsSetup/AdsCountryUnavailable/AdsCountryUnavailable';
import AdsDashboard from './AdsDashboard/AdsDashboard';
import AdsNotSetup from './AdsSetup/AdsNotSetup/AdsNotSetup';
import AdsOpportunities from './AdsOpportunities/AdsOpportunities';
import AdsSettings from './AdsSettings/AdsSettings';
import AdsSetupProcess from './AdsSetup/AdsSetupProcess';
import AppNavigation from '../../AppNavigation/AppNavigation';
import BoostedServicesSetupProcess from './BoostedServices/BoostedServicesSetupProcess';
import CompanyServiceArea from './CompanyServiceArea/CompanyServiceArea';
import ProductShutdownAlert from 'CompanyRole/productShutdownAlert';
import StripePaymentAlert from 'CompanyRole/stripePaymentAlert';




class CompanyAds extends React.Component {

  render(){
    var subNavLinks = [
      { primaryText: 'Dashboard', name:'dashboard', path: '/ads', exactPathMatch: true, icon: <DashboardIcon/> },
      { primaryText: 'Opportunities', path: '/ads/opportunities', icon: <CallIcon/> },
      { primaryText: 'Boosted Services', path: '/ads/boosted_services', icon: <BuildIcon/> },
      { primaryText: 'Service Area', path: '/ads/service_area', icon: <MapIcon/> },
      { primaryText: 'Ads Settings', path: '/ads/settings', icon: <SettingsIcon/> }
    ];

    const { companyAdsProfile, currentUserRole } = this.props;

    const canEdit = currentUserRole.roleHasAbility(Ability.EDIT_ADS_COMPANY_BOOSTED_SERVICE);


    return (
      <AppNavigation title="Ads">
        <Switch>
          <Route path="/ads/setup" component={AdsSetupProcess}/>
          <Route path="/ads/cannot_setup" component={AdsNotSetup}/>
          <Route path="/ads/country_unavailable" component={AdsCountryUnavailable}/>
          <Route path="/ads">
            <>
              {
                companyAdsProfile && companyAdsProfile.isSetupComplete ?
                  <SubNavigation links={subNavLinks}>
                    <StripePaymentAlert/>
                    <ProductShutdownAlert
                      profile={companyAdsProfile}
                      product='Ads'
                      onProcess={this.props.resubscribeToAds}
                    />

                    <Switch>
                      <Route exact path="/ads" component={AdsDashboard}/>
                      <Route path="/ads/opportunities" component={AdsOpportunities}/>
                      <Route exact path="/ads/boosted_services/:id(\d+)/add_to_balance" component={AddToBalance}/>
                      <Route path="/ads/boosted_services" component={BoostedServicesSetupProcess}/>
                      <Route path="/ads/service_area" component={CompanyServiceArea}/>
                      <Route path="/ads/settings" component={AdsSettings}/>
                      <Route path="*" component={props => <Redirect to='/'/>} />
                    </Switch>
                  </SubNavigation>
                :
                  canEdit ?
                    <Redirect to="/ads/setup"/>
                  :
                    <Redirect to="/ads/cannot_setup"/>
              }
            </>
          </Route>
        </Switch>
      </AppNavigation>
    );
  }

}




export default withContext(UserRoleContext, CompanyAdsProfileContext, SnackbarContext, CompanyAds);