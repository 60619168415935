import React from 'react';

import { Alert, isDevelopment, PromiseButton, Ability, CompanyRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { Card, CardContent } from '@material-ui/core';
import { Typography, Grid, Button, withStyles, CardMedia, CardActions, CardActionArea } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import { getSiteLink } from 'Sites/siteDesignPreviewHelpers';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import paperPadding from 'paperPadding';

class SiteDesignList extends React.Component {

  getSiteEditorUrl = siteDesignId => {
    let siteBuilderUrl;

    if (isDevelopment()) {
      siteBuilderUrl = window.location.origin.replace(':3000', ':3001');
    } else {
      siteBuilderUrl = window.location.origin + '/site_builder';
    }

    const companySiteDesign = this.props.companySiteDesigns.find(
      c => c.siteDesignId === siteDesignId
    );

    return siteBuilderUrl + `/sites/${companySiteDesign.siteId}`;
  }


  onOpenPreview = previewUrl => {
    if( previewUrl ){
      window.open( previewUrl );
    }
  }


  renderSiteCard = d => {
    const { classes } = this.props;
    const { selectedSiteDesignId, companySitesProfile } = this.props;

    const isShutdown = (
      companySitesProfile &&
      companySitesProfile.isSetupComplete &&
      companySitesProfile.isShutdown
    );


    let previewImage;
    let previewUrl;

    if( d && d.assetData ){
      previewImage = d.assetData.preview_image;
    }

    if( d && d.baseSiteId ){
      previewUrl = getSiteLink( d.baseSiteId );
    }

    const SiteCard = props => (
      <Grid item xs={6} key={d.id} style={{ paddingBottom: 15 }}>
        <Card>
          <CardActionArea onClick={() => this.onOpenPreview( previewUrl )}>
            {
              previewImage ?
                <CardMedia
                  className={classes.cardMedia}
                  image={previewImage}
                  title={d.name}
                />
              :
                <div className={classes.cardMedia}>
                  <PublicIcon className={classes.noDesignLogo} />
                </div>
            }
          </CardActionArea>

          <CardContent style={{ paddingBottom: 10 }}>
            <Typography variant="subtitle1">{d.name}</Typography>

            <CardActions>
              <Button
                variant={'outlined'}
                color={'secondary'}
                className={classes.previewButton}
                disabled={!Boolean(previewUrl)}
                component='a'
                href={previewUrl}
                target='_blank'
              >
                Preview
              </Button>

              {
                d.id === selectedSiteDesignId ?
                  null
                :
                  <PromiseButton
                    onProcess={() => this.props.onClickSetup(d)}
                    buttonProps={{
                      variant: 'contained',
                      color: 'primary',
                      disabled: isShutdown || !this.props.currentUserRole.roleHasAbility(Ability.EDIT_SITES_COMPANY_SITE_DESIGN),
                      fullWidth: true
                    }}
                  >
                    {this.props.setupText}
                  </PromiseButton>
              }

              {
                d.id === selectedSiteDesignId && !this.props.canLaunchEditor ?
                  <PromiseButton
                    onProcess={() => this.props.onClickSetup(d)}
                    buttonProps={{
                      variant: 'contained',
                      color: 'secondary',
                      disabled: isShutdown || !this.props.currentUserRole.roleHasAbility(Ability.EDIT_SITES_COMPANY_SITE_DESIGN),
                      fullWidth: true,
                    }}
                  >
                    Ready to Launch
                  </PromiseButton>
                :
                  null
              }
            </CardActions>
          </CardContent>
        </Card>
      </Grid>
    );


    return (
      selectedSiteDesignId === d.id ?
        <>
          <Grid
            item
            xs={3}
          />

          <SiteCard />

          <Grid
            item
            xs={3}
          />
        </>
      :
        <SiteCard />
    );
  }


  render() {
    const { classes } = this.props;
    const { availableSiteDesigns, selectedSiteDesignId, companySitesProfile } = this.props;

    if( !availableSiteDesigns.length ){
      return (
        <Alert warning>
          No site designs found for your selected plan. Please contact us for assistance.
        </Alert>
      );
    }


    const selectedSiteDesign = availableSiteDesigns.find(
      d => d.id === selectedSiteDesignId
    );

    return (
      <Grid container spacing={16}>
        {
          selectedSiteDesign ?
            <>
              <Grid item xs={12}>
                <h4>
                  Selected Site Design:
                </h4>
              </Grid>

              { this.renderSiteCard( selectedSiteDesign ) }
            </>
          :
            null
        }

        <Grid item xs={12}>
          <h4>
            Available Site Designs:
          </h4>
        </Grid>

        {
          availableSiteDesigns.filter(
            d => d.id !== selectedSiteDesignId
          ).map(
            d => this.renderSiteCard( d )
          )
        }
      </Grid>
    );
  }
}


SiteDesignList.defaultProps = {
  showBack: true,
  availableSiteDesigns: [],
  canLaunchEditor: false,
  getPreviewSiteLink: () => {},
  setupText: 'Build this design'
};


const styles = theme => ({
  paper: {
    padding: paperPadding
  },
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
  cardMedia: {
    width: '100%',
    height: 300,
    backgroundColor: 'whitesmoke',
    textAlign: 'center'
  },
  noDesignLogo: {
    color: 'grey',
    fontSize: 80,
    paddingTop: 110
  },
  previewButton: {
    marginRight: 'auto'
  }
});


export default withContext(
  CompanyRoleContext,
  SiteDesignsContext,
  CompanySitesProfileContext,
  withStyles(styles)(
    SiteDesignList
  )
);