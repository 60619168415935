import React from 'react';
import Axios from 'axios';
import { withContext } from 'kn-react';

import { UserRoleContext } from 'go-boost-library-react';

import FeedModel from './FeedModel';
import { LinearProgress } from '@material-ui/core';

const DEFAULT_STATE = {
  loading: true,
  feeds: [],
  companyFeeds: []
};


export const FeedContext = React.createContext(DEFAULT_STATE);


class FeedProvider extends React.Component {
  state = DEFAULT_STATE;

  componentDidMount = () => {
    this.fetchFeeds()
      .then(this.fetchCompanyFeeds)
      .then(() => this.setState({ loading: false }))
  }


  fetchFeeds = () => {
    const { currentUserRole } = this.props;
    const pathType = currentUserRole.isCompanyRole() ?
      'companies'
    :
      'organizations'
    ;

    return Axios.get(
      `/api/social/feeds/${pathType}/${currentUserRole.roleTypeId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const feeds = response.data.feeds.map(
        f => FeedModel.fromJSON(f)
      );

      this.setState({ feeds })
    })
  }


  fetchCompanyFeeds = () => {
    const { currentUserRole } = this.props;

    if( currentUserRole.isOrganizationRole() ){
      return;
    }

    return Axios.get(
      `/api/social/company_feeds/companies/${currentUserRole.roleTypeId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const companyFeeds = response.data.company_feeds.map(
        f => FeedModel.fromJSON(f)
      );

      this.setState({ companyFeeds })
    })
  }



  fetchFeedsForPlan = planId => {
    return Axios.get(
      `/api/social/feeds/plans/${planId}`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const { feeds } = this.state;

      response.data.feeds.forEach(
        r => {
          const feed = FeedModel.fromJSON(r);

          if( !feeds.find(f => f.id === feed.id ) ){
            feeds.push( feed );
          }
        }
      );

      this.setState({ feeds });

      return feeds
    })
  }


  createFeed = name => {
    return Axios.post(
      `/api/social/feeds/organizations/${this.props.currentUserRole.roleTypeId}`,
      { name },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(this.fetchFeeds)
  }


  subscribeToFeed = feedId => {
    if( this.props.currentUserRole.isOrganizationRole() ){
      return Promise.reject('Cannot subscribe to feeds.')
    }

    return Axios.post(
      `/api/social/company_feeds`,
      {
        company_id: this.props.currentUserRole.roleTypeId,
        feed_id: feedId
      },
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(this.fetchCompanyFeeds)
  }



  render() {
    if(this.state.loading) {
      return <LinearProgress/>;
    }

    return (
      <FeedContext.Provider
        value={{
          ...this.state,
          fetchFeeds: this.fetchFeeds,
          createFeed: this.createFeed,
          subscribeToFeed: this.subscribeToFeed,
          fetchFeedsForPlan: this.fetchFeedsForPlan,
        }}

      >
        {this.props.children}
      </FeedContext.Provider>
    );
  }
}


export default withContext(
  UserRoleContext,
  FeedProvider
);
