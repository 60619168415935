import React from 'react';
import { withContext, withProvider, log } from 'kn-react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Material
import { Assignment, Settings, Map as MapIcon, Call as CallIcon, Work as WorkIcon, MeetingRoom } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
// Kinertia
import { SubNavigation, Ability, CompanyRoleContext } from 'go-boost-library-react';
// App
import AppNavigation from '../../AppNavigation/AppNavigation';
import ChannelProvider from 'Channels/ChannelProvider';
import CompanyServiceArea from './CompanyServiceArea/CompanyServiceArea';
import CompanyVirtualProfileProvider, { CompanyVirtualProfileContext } from './CompanyVirtualProfile/CompanyVirtualProfileProvider';
import CompanyJobs from '../CompanyJobs/CompanyJobs';
import CompanyJob from '../CompanyJob/CompanyJob';
import MediaItemsProvider from 'MediaItems/MediaItemsProvider';
import Opportunity from 'Opportunity/Opportunity';
import ProductShutdownAlert from 'CompanyRole/productShutdownAlert';
import StripePaymentAlert from 'CompanyRole/stripePaymentAlert';
import VirtualDashboard from './VirtualDashboard/VirtualDashboard';
import VirtualFormDesignProvider from './VirtualFormDesigns/VirtualFormDesignProvider';
import VirtualFormsIndex from './VirtualFormDesigns/VirtualFormsIndex';
import VirtualSettings from './VirtualSettings/VirtualSettings';
import VirtualSetupProcess from './VirtualSetup/VirtualSetupProcess';
import VirtualWorkRequests from './VirtualWorkRequests/VirtualWorkRequests';
import Meetings from './Meetings/Meetings';
import MeetingDetails from './Meetings/MeetingDetails';
import VirtualNotSetup from './VirtualNotSetup/VirtualNotSetup';


class CompanyVirtual extends React.Component {

  render() {
    var subNavLinks = [
      { primaryText: 'Dashboard', path: '/virtual', exactPathMatch: true, icon: <DashboardIcon /> },
      { primaryText: 'Jobs', path: '/virtual/jobs', icon: <WorkIcon/> },
      { primaryText: 'Virtual Work Requests', path: '/virtual/virtual_work_requests', icon: <CallIcon/> },
      { primaryText: 'Virtual Work Request Designs', path: '/virtual/virtual_form_designs', icon: <Assignment />, isActive: () => /\/virtual\/virtual_form_designs|\/virtual\/company_virtual_form_designs/.test(window.location.pathname) },
      // { primaryText: 'Service Area', path: '/virtual/service_area', icon: <MapIcon/> },
      { primaryText: 'Virtual Settings', path: '/virtual/settings', icon: <Settings /> },
      { primaryText: 'Meetings', path: '/virtual/meetings', icon: <MeetingRoom /> },
    ];

    const { companyVirtualProfile, currentUserRole } = this.props;

    const canEdit = (currentUserRole.roleHasAbility(Ability.EDIT_VIRTUAL_COMPANY_VIRTUAL_PROFILE)) ? true : false;


    return (
      <AppNavigation title="Virtual">
        <Switch>
          <Route path="/virtual/setup" component={VirtualSetupProcess} />
          <Route path="/virtual/cannot_setup" component={VirtualNotSetup} />
          <Route path="/virtual">
            {
              companyVirtualProfile && companyVirtualProfile.isSetupComplete ?
                <SubNavigation links={subNavLinks}>
                  <StripePaymentAlert />
                  <ProductShutdownAlert
                    profile={companyVirtualProfile}
                    product='Virtual'
                    onProcess={this.props.resubscribeToVirtual}
                  />

                  <VirtualFormDesignProvider>
                    <Switch>
                      <Route path="/virtual/jobs/opportunities/:id" component={CompanyJob} />
                      <Route path="/virtual/jobs" component={CompanyJobs} />
                      <Route path="/virtual/virtual_work_requests" component={VirtualWorkRequests} />
                      <Route path="/virtual/virtual_form_designs" component={VirtualFormsIndex} />
                      {/* <Route path="/virtual/service_area" component={CompanyServiceArea} /> */}
                      <Route path="/virtual/settings" component={VirtualSettings} />
                      <Route exact path="/virtual/meetings/:id" component={MeetingDetails} />
                      <Route exact path="/virtual/meetings" component={Meetings} />
                      <Route exact path="/virtual" component={VirtualDashboard} />
                      <Route path="*" component={props => <Redirect to='/'/>} />
                    </Switch>
                  </VirtualFormDesignProvider>
                </SubNavigation>
              :
                canEdit ?
                  <Redirect to="/virtual/setup" />
                :
                  <Redirect to="/virtual/cannot_setup"/>
            }
          </Route>
        </Switch>
      </AppNavigation>
    );
  }

}

export default withProvider(
  CompanyVirtualProfileProvider,
  MediaItemsProvider,
  ChannelProvider,
  withContext(
    CompanyRoleContext,
    CompanyVirtualProfileContext,
    CompanyVirtual
  )
)