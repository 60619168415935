import React from 'react';
import { withContext, AutoError, log } from 'kn-react';
import { PromiseButton, SnackbarContext, ToggleMapModel } from 'go-boost-library-react';

import { Button, Grid, Typography, withStyles } from '@material-ui/core';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import ToggleMapForm from 'Ads/CompanyAds/BoostedServices/BoostZone/ToggleMapForm/ToggleMapForm';

class ServiceArea extends React.Component {
  state = {
    serviceArea: new ToggleMapModel()
  }



  componentDidMount = () => {
    const { company } = this.props;
    const { serviceArea } = company;
    if (serviceArea) {
      this.setState({ serviceArea });
    }
    if (this.props.initialStatePath.includes('ads/setup')) {
      this.sendAdsSegmentEvents();
    } else if(this.props.initialStatePath.includes('sites/setup')) {
      this.sendSitesSegmentEvents();
    }
  }



  sendAdsSegmentEvents = () => {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(currentUserRole.userId, 'finished_ads_settings_step', getUserRoleAuthHeaders);

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      'started_ads_service_area_step',
      getUserRoleAuthHeaders,
      { funnel: true }
    );
  }



  sendSitesSegmentEvents = () => {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(currentUserRole.userId, 'finished_sites_logo_step', getUserRoleAuthHeaders);

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      'started_sites_service_area_step',
      getUserRoleAuthHeaders,
      { funnel: true }
    );
  }



  onToggleMapChange = toggleMapModel => {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;
    trackCurrentUserSegmentEvent(currentUserRole.userId, 'service_area_change', getUserRoleAuthHeaders)
    this.setState({ serviceArea: toggleMapModel });
  }



  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }



  onClickNext = () => {
    const { transitionTo, transitions } = this.props;
    const { serviceArea } = this.state;
    return this.props.updateCompany(
      { serviceArea }
    ).then(
      () => transitionTo('next', transitions)
    ).catch(
      AutoError.catch.bind(this)
    );
  }



  onError = error => {
    log('error', error);
    this.props.showSnackbar(
      error.response ?
        error.response.data.message || 'Error'
      :
        'Error'
    );
  }



  render() {
    const { company, classes } = this.props;
    const { serviceArea } = this.state;

    const ProductUndoResubscriptionAlert = this.props.productUndoResubscriptionAlert;

    return (
      <div className={classes.root}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <ProductUndoResubscriptionAlert />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Set your Company Service Area</Typography>
            <Typography variant="body2" paragraph>This is the area your company serves.</Typography>
          </Grid>

          {/* <CancelSetupButton /> */}

          <Grid item xs={12}>

            <ToggleMapForm
              toggleMapModel={serviceArea}
              onToggleMapChange={this.onToggleMapChange}
              latitude={company.latitude}
              longitude={company.longitude}
            >
              <Grid container spacing={16}>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={this.onClickBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <PromiseButton
                    onProcess={this.onClickNext}
                    buttonProps= {{
                      variant: 'contained',
                      color: 'primary',
                      type: 'submit',
                      disabled: serviceArea.includedGeographies.length < 1
                    }}
                  >
                    Next
                  </PromiseButton>
                </Grid>
              </Grid>

            </ToggleMapForm>
          </Grid>
        </Grid>
      </div>
    );
  }

}



ServiceArea.defaultProps = {
  productUndoResubscriptionAlert: () => null
}



const styles = theme => ({
  root: { padding: 16 }
});


export default withStyles(styles)(
  withContext(
    SnackbarContext,
    TransitionContext,
    ServiceArea
  )
);
