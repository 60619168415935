import React from 'react';
import { format } from 'kn-react';
import { AdsChip, SitesChip, ReviewsChip, SocialChip, VirtualChip } from 'go-boost-library-react';
import { List, ListItem, ListItemText, withStyles } from '@material-ui/core';



const listSubscribedPlans = props => {
  const { subscribedPlans, classes, subscribedAnnualDomainPlan } = props;

  const plans = subscribedPlans;
  if( subscribedAnnualDomainPlan ){
    plans.push( subscribedAnnualDomainPlan );
  }

  return (
    <List>
      { plans.map(plan => {
        const isSites = (
          plan.isSitesIncluded ||
          ( subscribedAnnualDomainPlan && subscribedAnnualDomainPlan.id === plan.id )
        );

        return (
          <ListItem key={plan.id} classes={{ root: classes.listItemRoot }}>
            <ListItemText
              primary={plan.name}
              primaryTypographyProps={{ variant: 'h5', gutterBottom: true }}
              secondary={
                <>
                  {plan.isAdsIncluded ? <AdsChip /> : null}
                  {isSites ? <SitesChip /> : null}
                  {plan.isReviewsIncluded ? <ReviewsChip /> : null}
                  {plan.isSocialIncluded ? <SocialChip /> : null}
                  {plan.isVirtualIncluded ? <VirtualChip /> : null}
                </>
              }
            />

            <ListItemText
              classes={{ root: classes.priceRoot }}
              primary={
                <>
                  <span className={classes.price}>
                    { format.money(plan.planAmountCents / 100) }
                  </span>

                  &nbsp;

                  <span className={classes.period}>
                    { plan.billingPeriod || '' }
                  </span>
                </>
              }
              primaryTypographyProps={{ variant: 'h6' }}
            // secondary={`${ format.money(plan.setupFeeCents / 100) } setup`}
            // secondaryTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
            />
          </ListItem>
        );
      })}
    </List>
  );

}


const styles = theme => ({
  listItemRoot: {
    backgroundColor: '#FAFAFA',
    border: '1px solid #F5F5F5',
    borderRadius: 5,
    padding: 20,
  },
  priceRoot: {
    textAlign: 'right'
  },
  price: {
    color: theme.palette.primary.main,
    fontSize: 32,
  },
  period: {
    color: '#7f8788',
    fontSize: 16,
  },
  noSubscriptions: {
    textAlign: 'left',
    padding: 25,
  }
});

export default withStyles(styles)(
  listSubscribedPlans
);