import React from 'react';

import { OrganizationRoleContext } from 'go-boost-library-react';
import { withContext, log } from 'kn-react';

import { withStyles, Grid } from '@material-ui/core';

import OrganizationTable from '../OrganizationTable/OrganizationTable';

class OrganizationIndex extends React.Component {

  render() {
    const { organization, classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid
          spacing={16}
          container
        >
          <Grid item xs={12}>
            <OrganizationTable
              organization={organization}
              showDescendantsInfo={true}
            />
          </Grid>
        </Grid>
      </div>
    );
  }

}


const styles= theme => ({
  root: {
    padding: 16,
  }
});

export default withStyles(styles)(
  withContext(
    OrganizationRoleContext,
    OrganizationIndex
  )
);