import React from 'react';
import { withContext, withProvider, log } from 'kn-react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Material
import DashboardIcon from '@material-ui/icons/Dashboard';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import SettingsIcon from '@material-ui/icons/Settings';
// Kinertia
import { SubNavigation, Ability, CompanyRoleContext } from 'go-boost-library-react';
// App
import CompanySocialProfileProvider, { CompanySocialProfileContext } from './CompanySocialProfile/CompanySocialProfileProvider';
import FacebookAccountProvider from 'FacebookAccount/FacebookAccountProvider';
import AppNavigation from '../../AppNavigation/AppNavigation';
import SocialDashboard from './SocialDashboard';
import SocialNotSetup from './SocialNotSetup/SocialNotSetup';
import SocialSetupProcess from './SocialSetup/SocialSetupProcess';
import Feed from './Feed/Feed';
import Timeline from 'Social/Timeline/Timeline';
import FeedTimeline from './Feed/FeedTimeline';
import SocialSettings from 'Social/CompanySocial/SocialSettings/SocialSettings';
import EditFeedItem from 'Social/FeedItem/EditFeedItem';
import ViewFeedItem from 'Social/CompanySocial/ViewFeedItem/ViewFeedItem';
import MediaItems from 'Social/MediaItems/MediaItems';
import NewMediaItem from 'Social/MediaItems/NewMediaItem';
import EditMediaItems from 'Social/MediaItems/EditMediaItems';
import StripePaymentAlert from 'CompanyRole/stripePaymentAlert';
import ProductShutdownAlert from 'CompanyRole/productShutdownAlert';


class CompanySocial extends React.Component {

  render() {
    var subNavLinks = [
      { primaryText: 'Dashboard', path: '/social', exactPathMatch: true, icon: <DashboardIcon /> },
      { primaryText: 'Feeds', path: '/social/feeds', icon: <CalendarViewDayIcon />, isActive: () => /\/social\/feeds|\/social\/feed_items/.test(window.location.pathname) },
      { primaryText: 'Timeline', path: '/social/timeline', icon: <CalendarIcon /> },
      { primaryText: 'Media Items', path: '/social/media_items', icon: <PermMediaIcon /> },
      { primaryText: 'Social Settings', path: '/social/settings', icon: <SettingsIcon /> },
    ];

    const { companySocialProfile, currentUserRole } = this.props;

    const canEdit = (currentUserRole.roleHasAbility( Ability.EDIT_SOCIAL_COMPANY_SOCIAL_PROFILE )) ? true : false;


    return (
      <AppNavigation title="Social">
        <Switch>
          <Route path="/social/setup" component={SocialSetupProcess} />
          <Route path="/social/cannot_setup" component={SocialNotSetup} />
          <Route path="/social">
            {
              companySocialProfile && companySocialProfile.isSetupComplete ?
                <SubNavigation links={subNavLinks}>
                  <StripePaymentAlert />
                  <ProductShutdownAlert
                    profile={companySocialProfile}
                    product='Social'
                    onProcess={this.props.resubscribeToSocial}
                  />

                  <Switch>
                    <Route exact path="/social" component={SocialDashboard} />
                    <Route exact path="/social/feeds" component={Feed} />
                    <Route path="/social/feeds/:feedId/timeline" component={FeedTimeline} />
                    <Route exact path="/social/feed_items/:feedItemId/view" component={ViewFeedItem} />
                    <Route path="/social/feed_items/:feedItemId/edit" component={EditFeedItem} />
                    <Route exact path="/social/timeline" component={Timeline} />
                    <Route exact path="/social/media_items" component={MediaItems} />
                    <Route exact path="/social/media_items/new" component={NewMediaItem} />
                    <Route path="/social/media_items/:id/edit" component={EditMediaItems} />
                    <Route path="/social/settings" component={SocialSettings} />
                    <Route path="*" component={props => <Redirect to='/'/>} />
                  </Switch>
                </SubNavigation>
              :
                canEdit ?
                  <Redirect to="/social/setup" />
                :
                  <Redirect to="/social/cannot_setup"/>
            }
          </Route>
        </Switch>
      </AppNavigation>
    );
  }

}

export default withProvider(
  CompanySocialProfileProvider,
  FacebookAccountProvider,
  withContext(
    CompanyRoleContext,
    CompanySocialProfileContext,
    CompanySocial
  )
)