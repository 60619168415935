import React from 'react';
import { withContext, MaxWidth, AutoError, log } from 'kn-react';
import { Grid, withStyles, Typography, Paper, Button } from '@material-ui/core';
import ReviewTrackersLocationCard from '../../ReviewTrackersLocation/ReviewTrackersLocationCard';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import { CompanyReviewsProfileContext } from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import paperPadding from 'paperPadding';
import { SnackbarContext } from 'go-boost-library-react';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';

class ReviewTrackersLocationSetup extends React.Component {

  componentDidMount() {
    trackCurrentUserSegmentEvent(this.props.currentUserRole.userId, 'started_reviews_review_settings_step', this.props.getUserRoleAuthHeaders);
  }


  onClickNext = () => {
    return this.props.transitionTo('next', this.props.transitions);
  }


  onChangeCompanyReviewsProfile = companyReviewsProfile => {
    this.setState({ companyReviewsProfile })
  }


  onValidationError = error => {
    log('error', error);
    this.props.showSnackbar(error.message);
  }

  onError = error => {
    log('error', error);
    this.props.showSnackbar(error.response.data.message);
  }


  render() {
    const { classes } = this.props;

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={6}>
            <Typography variant="h5">Reviews Settings</Typography>
          </Grid>

          {/* <CancelSetupButton /> */}

          <Grid item xs={12}>

            <ReviewTrackersLocationCard
              reviewTrackersLocation={this.props.reviewTrackersLocation}
              wrapperComponent={props => <Paper className={classes.paperRoot}>{ props.children }</Paper>}
            >
              {
                props => (
                  <div style={{ paddingTop: 16 }}>
                    <Grid container spacing={16}>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => this.props.transitionTo('back', this.props.transitions)}
                        >
                          Back
                        </Button>
                      </Grid>

                      <Grid item xs={6} style={{ textAlign: 'right' }}>
                        <Button
                          variant="contained"s
                          color="primary"
                          onClick={() => {
                            return props.onProcess()
                              .then(this.onClickNext)
                              .catch(AutoError.catch.bind(this));
                          }}
                        >
                          Next
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                )
              }
            </ReviewTrackersLocationCard>

          </Grid>


        </Grid>
      </MaxWidth>
    );
  }

}





const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
    paddingTop: 5,
  },
  companyShortNameExplanation: {
    marginTop: 35,
    marginBottom: 20
  }
})


export default withStyles(styles)(
  withContext(
    SnackbarContext,
    CompanyReviewsProfileContext,
    TransitionContext,
    ReviewTrackersLocationSetup
  )
);
