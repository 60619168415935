import React from 'react';
import Axios from 'axios';

import { MaxWidth } from 'kn-react';
import { CompanyModel } from 'go-boost-library-react';

import { Typography, Paper, CircularProgress, Link, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import paperPadding from 'paperPadding';
import ReviewRequestModel from '../../ReviewRequestModel';
import ReviewSourceModel from '../../ReviewSourceModel';


class ReviewRequestLandingPage extends React.Component {
  state = {
    loading: true,
    referenceId: '',
    reviewRequest: {},
    companyReviewSites: [],
    reviewSources: [],
    company: {}
  };

  componentDidMount = () => {
    const referenceId = this.props.match.params.referenceId;

    const callback = () => {
      this.fetchReviewRequest()
        .then(this.fetchCompanyReviewSites)
        .then(this.fetchReviewSources)
        .then(this.fetchCompany)
        .finally(() => this.setState({ loading: false }))
    }

    this.setState(
      { referenceId },
      callback
    )
  }

  fetchReviewRequest = () => {
    const { referenceId } = this.state;

    return Axios.get(
      `/api/reviews/review_requests/review_request_landing_page/review_request/${referenceId}`
    )
      .then(response => {
        const reviewRequest = ReviewRequestModel.fromJSON(response.data.review_request)
        this.setState({ reviewRequest });
      })
  }


  fetchCompanyReviewSites = () => {
    const { reviewRequest } = this.state;

    return Axios.get(
      `/api/reviews/review_requests/review_request_landing_page/company_review_sites/${reviewRequest.companyId}`
    )
      .then(response => {
        const companyReviewSites = response.data.company_review_sites.map(r => ReviewRequestModel.fromJSON(r));
        this.setState({ companyReviewSites });
      })
  }


  fetchReviewSources = () => {
    return Axios.get(
      `/api/reviews/review_requests/review_request_landing_page/review_sources`
    )
      .then(response => {
        const reviewSources = response.data.review_sources.map(r => ReviewSourceModel.fromJSON(r));
        this.setState({ reviewSources });
      })
  }


  fetchCompany = () => {
    const { reviewRequest } = this.state;

    return Axios.get(
      `/api/reviews/review_requests/review_request_landing_page/company/${reviewRequest.companyId}`
    )
      .then(response => {
        const company = CompanyModel.fromJSON(response.data.company);
        this.setState({ company });
      })
  }

  createReviewSiteEntry = companyReviewSite => {
    const { classes } = this.props;
    const { reviewSources } = this.state;
    const reviewSource = reviewSources.find(r => companyReviewSite.reviewSourceId === r.id);

    return (
      <Grid item xs={12} key={companyReviewSite.id}>
        <a href={companyReviewSite.url} target="_blank" style={{ textDecoration: 'none' }}>
          <Paper className={classes.reviewSite}>
            <img src={reviewSource.image} />
            <Typography variant="caption">
              <Link color="secondary">Review us on {reviewSource.name}</Link>
            </Typography>
          </Paper>
        </a>
      </Grid>
    )
  }


  render() {
    const { classes } = this.props;
    const { loading, companyReviewSites, company } = this.state;

    if (loading) {
      return (<CircularProgress />)
    }

    const companyName = company.name || '';

    return (
      <MaxWidth maxWidth={800}>
        <div className={classes.header}>
          <Grid container spacing={16}>
            <Grid item xs={12} >
              <Typography paragraph variant="h4">Thank you for the opportunity to serve you!</Typography>
              <Typography paragraph variant="h2" color="primary">{companyName}</Typography>
              <Typography paragraph variant="body2">Our team strives for the very best service. Let us know how we did this time by posting a review on one of the sites below!</Typography>
            </Grid>


            {companyReviewSites.map(r => this.createReviewSiteEntry(r))}
          </Grid>
        </div>
      </MaxWidth>
    );
  }

}


const styles = theme => ({
  header: {
    padding: paperPadding,
    textAlign: 'center'
  },
  reviewSite: {
    padding: paperPadding,
    textAlign: 'center'
  }
});

export default withStyles(styles)(ReviewRequestLandingPage);