import React from 'react';

import { Ability, Alert, isDevelopment, PromiseButton, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, log, MaxWidth } from 'kn-react';

import { Card, CardHeader, CardContent } from '@material-ui/core';
import { Settings, OpenInNew, Edit } from '@material-ui/icons';
import { Typography, LinearProgress, Paper, Grid, Button, withStyles, Toolbar, Tooltip, IconButton } from '@material-ui/core';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import AdCarousel from 'Ads/CompanyAds/BoostedServices/BoostedServicesList/AdCarousel/AdCarousel';
import paperPadding from 'paperPadding';

class SiteDesignList extends React.Component {

  getSiteEditorUrl = siteDesignId => {
    let siteBuilderUrl;

    if (isDevelopment()) {
      siteBuilderUrl = window.location.origin.replace(':3000', ':3001');
    } else {
      siteBuilderUrl = window.location.origin + '/site_builder';
    }

    const companySiteDesign = this.props.companySiteDesigns.find(
      c => c.siteDesignId === siteDesignId
    );

    return siteBuilderUrl + `/sites/${companySiteDesign.siteId}`;
  }

  render() {
    const { classes } = this.props;
    const { availableSiteDesigns, selectedSiteDesignId, companySitesProfile } = this.props;

    const isShutdown = (
      companySitesProfile &&
      companySitesProfile.isSetupComplete &&
      companySitesProfile.isShutdown
    );

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>

          <Grid item xs={12}>
            {
              availableSiteDesigns.length ?

                availableSiteDesigns.map(d => {
                  let carousel;
                  if (d.assetData && d.assetData.carousel) {
                    carousel = d.assetData.carousel;
                  }

                  const previewLink = this.props.getPreviewSiteLink(d);

                  return (
                    <Grid item xs={12} key={d.id} style={{ paddingBottom: 15 }}>
                      <Card>

                        <CardHeader
                          className={classes.cardHeader}
                          disableTypography
                          title={
                            <Typography variant="h6">{d.name}</Typography>
                          }
                          subheader=""
                          avatar={null}
                          action={
                            this.props.canLaunchEditor ?
                              <Toolbar disableGutters>
                                {
                                  d.surveyId && this.props.currentUserRole.roleHasAbility(Ability.EDIT_SITES_COMPANY_SITE_DESIGN) ?
                                    <Tooltip title="Edit Site Settings" enterDelay={500}>
                                      <IconButton onClick={() => this.props.onClickEditSettings(d)}>
                                        <Settings />
                                      </IconButton>
                                    </Tooltip>
                                    :
                                    null

                                }

                                {
                                  // this.props.canLaunchEditor && this.props.currentUserRole.roleHasAbility(Ability.EDIT_SITES_COMPANY_SITE_DESIGN) ?
                                  //   <Tooltip title="Edit Site Design" enterDelay={500}>
                                  //     <IconButton component='a' href={this.getSiteEditorUrl(d.id)}>
                                  //       <Edit />
                                  //     </IconButton>
                                  //   </Tooltip>
                                  //   :
                                  //   null
                                }

                                {
                                  previewLink ?
                                    <Tooltip title="Visit Site" enterDelay={500}>
                                      <IconButton component="a" href={previewLink} target="_blank">
                                        <OpenInNew />
                                      </IconButton>
                                    </Tooltip>
                                    : null
                                }

                              </Toolbar>
                              :
                              null
                          }
                        />

                        {
                          carousel ?
                            <AdCarousel data={carousel} itemWidth={265} />
                            : null
                        }


                        {
                          d.id === selectedSiteDesignId ?
                            null :
                            <CardContent>

                              <PromiseButton
                                onProcess={() => this.props.onClickSetup(d)}
                                buttonProps={{
                                  variant: 'contained',
                                  color: 'primary',
                                  disabled: isShutdown || !this.props.currentUserRole.roleHasAbility(Ability.EDIT_SITES_COMPANY_SITE_DESIGN),
                                  fullWidth: true,
                                }}
                              >
                                {this.props.setupText}

                              </PromiseButton>
                            </CardContent>
                        }


                        {
                          d.id === selectedSiteDesignId && !this.props.canLaunchEditor ?
                            <CardContent>

                              <PromiseButton
                                onProcess={() => this.props.onClickSetup(d)}
                                buttonProps={{
                                  variant: 'contained',
                                  color: 'secondary',
                                  disabled: isShutdown || !this.props.currentUserRole.roleHasAbility(Ability.EDIT_SITES_COMPANY_SITE_DESIGN),
                                  fullWidth: true,
                                }}
                              >
                                Ready to Launch
                              </PromiseButton>
                            </CardContent>
                            : null
                        }



                      </Card>
                    </Grid>

                  );

                })
                :
                <Alert warning>No site designs found for your selected plan. Please contact us for assistance.</Alert>
            }

          </Grid>

        </Grid>
      </MaxWidth>
    );
  }

}


SiteDesignList.defaultProps = {
  showBack: true,
  availableSiteDesigns: [],
  canLaunchEditor: false,
  getPreviewSiteLink: () => {},
  setupText: 'Build this design'
};


const styles = theme => ({
  paper: {
    padding: paperPadding
  },
  cardHeader: {
    borderBottom: '1px solid #efefef',
  },
});


export default withContext(
  CompanyRoleContext,
  SiteDesignsContext,
  CompanySitesProfileContext,
  withStyles(styles)(
    SiteDesignList
  )
);