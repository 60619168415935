import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

import { MaxWidth } from 'kn-react';

import { Paper, Grid, Typography, List, ListItem, ListItemIcon, ListItemText, withStyles, Checkbox, Button, Link } from '@material-ui/core';
import { CheckCircleOutline, CheckBox, CheckBoxOutlined, IndeterminateCheckBox } from '@material-ui/icons';

import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';


class ProductCompletionPage extends React.Component {
  render(){
    const { classes } = this.props;

    return(
      <MaxWidth maxWidth={1000} style={{ padding: 16 }}>
        <Grid container spacing={8}>
          <Grid item xs={6}>
            <Typography variant="h5">Get started with { this.props.product }</Typography>
          </Grid>

          {/* <CancelSetupButton /> */}
        </Grid>

        <Paper className={classes.paperRoot}>
          <Grid container spacing={8}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <CheckCircleOutline style={{ fontSize: 100 }} color="primary"/>

              <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                { this.props.product } has been successfully setup.
              </Typography>
            </Grid>

            {
              this.props.nextSteps.length ?
                <Grid item xs={12} sm={12} style={{ textAlign: 'center' }}>
                  <Typography variant="body1">
                    Now that you setup { this.props.product }, here are a few things to do to get started:
                  </Typography>

                  <List style={{ textAlign: 'center', width: '50%', marginLeft: '25%' }}>
                    {
                      this.props.nextSteps.map(
                        (s, i) => (
                          <ListItem
                            key={i}
                            component={s.link && !s.link.startsWith('http') ? RouterLink : Link}
                            to={s.link && !s.link.startsWith('http') ? s.link : undefined}
                            href={s.link && !s.link.startsWith('http') ? undefined : s.link}
                            target={s.link && !s.link.startsWith('http') ? undefined : '_blank'}
                          >
                            <ListItemIcon>
                              {
                                s.checked ? <CheckBox /> : <IndeterminateCheckBox />
                              }
                            </ListItemIcon>

                            <ListItemText>
                              { s.text }
                            </ListItemText>
                          </ListItem>
                        )
                      )
                    }
                  </List>
                </Grid>
              :
                null
            }

            <Grid container item xs={12} sm={12} className={classes.productLinks}>
              {/* {
                this.props.productLinks.map(
                  (l, i) => (
                    <Grid item xs={12} key={i}>
                      <Button variant="contained" component={Link} to={ l.link } className={classes.button}>
                        { l.text }
                      </Button>
                    </Grid>
                  )
                )
              } */}

              <Grid item xs={12}>
                <Button variant='outlined' component={RouterLink} to={ this.props.dashboardLink } className={classes.button}>
                  Go to Dashboard
                </Button>
              </Grid>

            </Grid>
          </Grid>
        </Paper>
      </MaxWidth>
    )
  }
}


const styles = theme => ({
  paperRoot: {
    padding: 15
  },
  button: {
    width: 200
  },
  productLinks: {
    textAlign: 'center'
  },
  dashboardLink: '/'
})


ProductCompletionPage.defaultProps = {
  product: 'Ads',
  nextSteps: [],
  productLinks: []
}


export default withStyles(styles)(ProductCompletionPage)