import React from 'react';

import { log, withContext, formHelpers, usStatesList } from 'kn-react';
import { SnackbarContext, CompanyRoleContext } from 'go-boost-library-react';

import { Grid, withStyles, Typography, TextField } from '@material-ui/core';

import { ReviewTrackersLocationContext } from './ReviewTrackersLocationProvider';
import paperPadding from 'paperPadding';


const pluckKeys = (obj, keysToPluck, accessor = (o, k) => o[k]) => {
  // Keys can be an array of keys or a template object.
  keysToPluck = obj.constructor === Array ? keysToPluck : Object.keys(keysToPluck);

  const newObj = {};
  keysToPluck.forEach(k => {
    newObj[k] = accessor(obj, k);
  });

  return newObj;
};


class ReviewTrackersLocationCard extends React.Component {

  fields = {
    name: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    state: '',
    postalCode: '',
    phoneNumber: '',
    country: ''
  };


  state = {
    ...this.fields,
  };


  componentDidMount = () => {
    const { reviewTrackersLocation } = this.props;
    if(!reviewTrackersLocation) {
      this.props.createReviewTrackersLocation()
        .then(reviewTrackersLocation => this.pluckFormProps(reviewTrackersLocation));
    } else {
      this.pluckFormProps(reviewTrackersLocation);
    }
  }


  componentWillReceiveProps = nextProps => {
    if (this.props.reviewTrackersLocation !== nextProps.reviewTrackersLocation) {
      this.pluckFormProps(nextProps.reviewTrackersLocation);
    }
  }


  pluckFormProps = reviewTrackersLocation => {
    const { company } = this.props;

    if (!reviewTrackersLocation) return;

    this.setState({
      ...pluckKeys(
        reviewTrackersLocation,
        this.fields,
        (l,k) => l[k] || company[k]
      )
    });
  }

  onProcess = () => {
    return this.validate()
      .then(this.updateReviewTrackersLocation)
      .then(() => this.props.showSnackbar('Review Settings updated.'));
      // You MUST catch this with `AutoError.catch.bind(this)` in the parent
      // that calls this, and implement `onValidationError`.
  }

  updateReviewTrackersLocation = () => {
    log('updateReviewTrackersLocation');

    return this.props.updateReviewTrackersLocation(
      {
        name: this.state.name,
        address_line_one: this.state.addressLineOne,
        address_line_two: this.state.addressLineTwo,
        city: this.state.city,
        state: this.state.state,
        postal_code: this.state.postalCode,
        phone_number: this.state.phoneNumber,
        country: this.state.country,
      }
    )
  }

  validate = () => {
    const validations = {
      name: {
        presence: { message: "Please specify your company's name." }
      },
      addressLineOne: {
        presence: { message: "Please specify your company's address." }
      },
      city: {
        presence: { message: "Please specify your company's city." }
      },
      state: {
        presence: { message: "Please specify your company's state." }
      },
      postalCode: {
        presence: { message: "Please specify your company's postal code." }
      },
      phoneNumber: {
        presence: { message: "Please specify your company's phone number." },
        length: { equalTo: 10, message: "Phone number must be 10 digits." }
      },
      country: {
        presence: { message: "Please specify your company's country." }
      }
    };

    return formHelpers.validate(this.state, validations);
  }

  render() {
    const Wrapper = this.props.wrapperComponent || React.Fragment;
    const Children = props => {
      const Child = this.props.children;
      return <Child {...props} onProcess={this.onProcess}/>
    };


    return (
      <>
        <Wrapper>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">Reviews Company Info</Typography>
              <Typography variant="caption">Please confirm your company's information. Our team will use this to go around the web and find your listings on review sites.</Typography>
              <TextField
                label="Company Name"
                id="name"
                value={this.state.name || ''}
                onChange={e => this.setState({ name: e.target.value })}
                onBlur={e => this.setState({ name: e.target.value.trim() })}
                fullWidth
                margin="normal"
                disabled={this.props.fieldsDisabled}
              />
              <TextField
                label="Address One"
                id="address-line-one"
                value={this.state.addressLineOne || ''}
                onChange={e => this.setState({ addressLineOne: e.target.value })}
                onBlur={e => this.setState({ addressLineOne: e.target.value.trim() })}
                fullWidth
                margin="normal"
                disabled={this.props.fieldsDisabled}
              />
              <TextField
                label="Address Two"
                id="address-line-two"
                value={this.state.addressLineTwo || ''}
                onChange={e => this.setState({ addressLineTwo: e.target.value })}
                onBlur={e => this.setState({ addressLineTwo: e.target.value.trim() })}
                fullWidth
                margin="normal"
                disabled={this.props.fieldsDisabled}
              />
              <TextField
                label="City"
                id='city'
                value={this.state.city || ''}
                onChange={e => this.setState({ city: e.target.value })}
                onBlur={e => this.setState({ city: e.target.value.trim() })}
                fullWidth
                margin="normal"
                disabled={this.props.fieldsDisabled}
              />
              <TextField
                select
                id="state"
                label="State"
                SelectProps={{ native: true }}
                onChange={e => this.setState({ state: e.target.value })}
                fullWidth
                value={this.state.state || ''}
                margin="normal"
                disabled={this.props.fieldsDisabled}
                inputProps={{ name: 'state', id: 'state' }} >
                {
                  usStatesList.map(s => <option key={s} value={s}>{s}</option>)
                }
              </TextField>
              <TextField
                label="Postal Code"
                id="postal-code"
                value={this.state.postalCode || ''}
                onChange={e => this.setState({ postalCode: e.target.value })}
                onBlur={e => this.setState({ postalCode: e.target.value.trim() })}
                fullWidth
                margin="normal"
                inputProps={{
                  maxLength: 5,
                  type: 'tel',
                  pattern: '[0-9]*',
                  noValidate: true
                }}
                disabled={this.props.fieldsDisabled}
              />
              <TextField
                label="Phone Number"
                id="phone-number"
                value={this.state.phoneNumber || ''}
                onChange={e => this.setState({ phoneNumber: e.target.value })}
                fullWidth
                inputProps={{
                  maxLength: 10,
                  type: 'tel',
                  pattern: '[0-9]*',
                  noValidate: true
                }}
                margin="normal"
                disabled={this.props.fieldsDisabled}
              />


            </Grid>
          </Grid>
        </Wrapper>

        <Children/>
      </>
    );
  }

}

const styles = theme => ({
  paperRoot: {
    padding: paperPadding,
  }
})

ReviewTrackersLocationCard.defaultProps = {
  showButton: true,
  fieldsDisabled: false,
}


export default withStyles(styles)(
  withContext(
    ReviewTrackersLocationContext,
    CompanyRoleContext,
    SnackbarContext,
    ReviewTrackersLocationCard
  )
);
