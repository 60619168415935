import React from 'react';
import { Button, Grid, Typography, withStyles, IconButton, Tooltip } from '@material-ui/core';
import { MaxWidth, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext } from 'go-boost-library-react';
import { Paper } from '@material-ui/core';
import VirtualFormDesignList from 'Virtual/VirtualFormDesign/VirtualFormDesignList/VirtualFormDesignList';
import { VirtualFormDesignContext } from './VirtualFormDesignProvider';
import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';


class VirtualFormDesigns extends React.Component {
  setupVirtualFormDesign = design => {
    return this.props.createCompanyVirtualFormDesign(
      design.id
    ).then(
      this.props.fetchCompanyVirtualFormDesigns
    ).then(
      () => this.props.showSnackbar('New Virtual Work Request Designs successfully setup.')
    );
  }

  render() {
    const { classes } = this.props;

    const existingVirtualFormDesignIds = this.props.companyVirtualFormDesigns.map(
      d => d.virtualFormDesignId
    );

    const existingVirtualFormDesigns = [];
    const availableVirtualFormDesigns = [];
    this.props.virtualFormDesigns.forEach(
      v => existingVirtualFormDesignIds.includes( v.id ) ?
        existingVirtualFormDesigns.push( v )
      :
        availableVirtualFormDesigns.push( v )
    );

    const canEdit = (
      this.props.companyVirtualProfile && !this.props.companyVirtualProfile.isShutdown
    );

    return (
      <MaxWidth maxWidth={1200}>
        <div className={classes.root}>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Typography variant="h6">Virtual Work Request Designs</Typography>
            </Grid>

            {
              existingVirtualFormDesigns && existingVirtualFormDesigns.length ?
                <Grid item xs={12}>
                  <Typography variant="body1">Existing Virtual Work Request Designs</Typography>
                </Grid>
              :
                null
            }

            <Grid item xs={12}>
              <VirtualFormDesignList
                virtualFormDesigns={existingVirtualFormDesigns}
                companyVirtualFormDesigns={this.props.companyVirtualFormDesigns}
                onSelectDesign={this.setupVirtualFormDesign}
                shouldSetupDesignWhenSelected={true}
                canEdit={canEdit}
              />
            </Grid>

            {
              availableVirtualFormDesigns && availableVirtualFormDesigns.length ?
                <Grid item xs={12}>
                  <Typography variant="body1">Available Virtual Work Request Designs</Typography>
                </Grid>
              :
                null
            }

            <Grid item xs={12}>
              <VirtualFormDesignList
                virtualFormDesigns={availableVirtualFormDesigns}
                companyVirtualFormDesigns={this.props.companyVirtualFormDesigns}
                onSelectDesign={this.setupVirtualFormDesign}
                shouldSetupDesignWhenSelected={true}
                canEdit={canEdit}
              />
            </Grid>
          </Grid>
        </div>
      </MaxWidth>
    );
  }
}


VirtualFormDesigns.defaultProps = {
  selectedVirtualFormDesignIds: []
}


const styles = theme => ({
  paperRoot: {
    padding: 16,
  },
  add: {
    textAlign: 'right'
  }
});

export default withContext(
  CompanyVirtualProfileContext,
  VirtualFormDesignContext,
  SnackbarContext,
  withStyles(styles)(
    VirtualFormDesigns
  )
);