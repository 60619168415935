import React from 'react';
import Axios from 'axios';

import { withContext, log, MaxWidth, formHelpers, AutoError } from 'kn-react';
import { SnackbarContext, PromiseButton } from 'go-boost-library-react';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import { Typography, LinearProgress, Paper, Grid, Button, withStyles, Divider } from '@material-ui/core';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import ImageUpload from 'ImageUpload/ImageUpload';
import paperPadding from 'paperPadding';
import SitesUndoResubscription from 'Sites/CompanySites/SitesSetup/SitesUndoResubscription/SitesUndoResubscription';
class LogoSetup extends React.Component {

  state = {
    logoImageFile: null,
    logoUrl: this.props.companySitesProfile.logoUrl || '',
  }

  componentDidMount() {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(currentUserRole.userId, 'finished_sites_survey_step', getUserRoleAuthHeaders);

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      'started_sites_logo_step',
      getUserRoleAuthHeaders,
      { funnel: true }
    );
  }

  onChangeLogoImage = logoImageFile => {
    log('onChangeLogoImage', logoImageFile);
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(currentUserRole.userId, 'sites_uploaded_logo', getUserRoleAuthHeaders);
    this.setState({ logoImageFile });
  }

  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }

  onClickNext = () => {
    return this.uploadLogoImage()
      .then(mediaItem => {
        return this.props.updateCompanySitesProfile({
          logo_url: mediaItem ? mediaItem.url : undefined
        });
      })
      .then(() => this.props.transitionTo('next', this.props.transitions))
      .catch(AutoError.catch.bind(this));
  }






  uploadLogoImage = () => {
    const { logoImageFile } = this.state;
    return logoImageFile ? this.props.createMediaItem(logoImageFile) : Promise.resolve(null);
  }


  render(){
    const { classes, companySitesProfile } = this.props;
    const { logoUrl } = this.state;

    return (
      <MaxWidth maxWidth={800} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <SitesUndoResubscription />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Upload Your Logo</Typography>
            <Typography variant="body2">We will use it to customize your site. You can always provide your logo later.</Typography>
          </Grid>

          {/* <CancelSetupButton /> */}

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Paper className={classes.paper}>
              <ImageUpload
                existingImageUrl={ logoUrl }
                onChangeImage={this.onChangeLogoImage}
              />
              <Typography variant="caption">Supported Image Formats: JPG, PNG, GIF</Typography>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              onClick={this.onClickBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button style={{ marginRight: 15 }} onClick={() => this.props.transitionTo('next', this.props.transitions)}>Skip</Button>

            <PromiseButton
              onProcess={this.onClickNext}
              buttonProps= {{
                variant: 'contained',
                color: 'primary',
                type: 'submit',
              }}
            >
              Next
            </PromiseButton>
          </Grid>
        </Grid>
      </MaxWidth>
    );
  }

}

const styles = theme => ({
  paper: {
    padding: paperPadding,
    maxWidth: '100%'
  }
});


export default withStyles(styles)(
  withContext(TransitionContext, SnackbarContext, LogoSetup)
);
