import React from 'react';
import Moment from 'moment';
import { format, withContext } from 'kn-react';
import { Link as RouterLink } from 'react-router-dom';
import { UserRoleContext } from 'go-boost-library-react';
import * as d3 from 'd3';


import { withStyles, Grid, Typography, IconButton, Tooltip, Button, List, ListItem, ListItemText, Paper, Divider, Link, ListSubheader, LinearProgress } from '@material-ui/core';
import { Edit, Delete, Send, RestoreFromTrash, Visibility } from '@material-ui/icons';
import FacebookPostButton from './FacebookPostButton';

class SocialTimeline extends React.Component {
  ownsItems = item => (
    item.roleType === this.props.currentUserRole.roleType && item.roleTypeId === this.props.currentUserRole.roleTypeId
  )

  isPastPost = item => (
    Moment( item.postAt ).isSameOrBefore( Moment() )
  )

  withinPostBlackoutWindow = item => (
    Moment( item.postAt ).subtract(5, 'minutes').isBefore( Moment() ) &&
    Moment( item.postAt ).add(5, 'minutes').isAfter( Moment() )
  )

  dot = ( item, index ) => {
    const postAt = Moment.utc( item.postAt.toString() ).local();

    return(
      <Grid
        container
        item
        xs={12}
        style={{
          // marginBottom: 0,
          opacity: (
            (
              !this.props.isFeedItemPosted( item ) && (
                !item.shouldPost || this.isPastPost( item )
              )
            ) ?
              0.5
            :
              1
          ),
        }}
      >
        <Grid item xs={1}>
          <span
            className={this.props.classes.dot}
            style={{
              backgroundColor: this.ownsItems(item) ? this.props.theme.palette.primary.main : '#bbb'
            }}
          />
        </Grid>

        <Grid item xs={8}>
          <div style={{ verticalAlign: 'middle' }}>
            <Typography variant='h6' className={this.props.classes.timestamp}>
              { format.dateTime( postAt ) } { this.props.isFeedItemPosted( item ) ? '- Posted' : '' }
            </Typography>

            {
              this.props.showViewButtonForItem( item ) ?
                <Tooltip title={'View Posted Feed Item'}>
                  <IconButton
                    component={RouterLink}
                    to={this.props.fetchViewLink(item)}
                  >
                    <Visibility fontSize='small'/>
                  </IconButton>
                </Tooltip>
              :
                null
            }

            {
              this.props.showEditButton && !this.isPastPost( item ) && item.shouldPost && !this.props.isFeedItemPosted( item ) && !this.withinPostBlackoutWindow( item ) ?
                <Tooltip title='Edit Feed Item'>
                  <IconButton
                    component={ this.ownsItems(item) ? RouterLink : Button }
                    to={ this.ownsItems(item) ? this.props.fetchEditLink(item) : undefined }
                    onClick={ this.ownsItems(item) ? undefined : this.props.fetchEditLink(item) }
                  >
                    <Edit fontSize='small'/>
                  </IconButton>
                </Tooltip>
              :
                this.props.showEditButton && !item.shouldPost && !this.props.isFeedItemPosted( item ) && !this.withinPostBlackoutWindow( item ) ?
                  <Tooltip title={'Restore Feed Item'}>
                    <IconButton onClick={() => this.props.onRestoreItem(item)}>
                      <RestoreFromTrash fontSize='small'/>
                    </IconButton>
                  </Tooltip>
                :
                  null
            }

            {
              this.props.showDeleteButton && !this.isPastPost( item ) && item.shouldPost && !this.props.isFeedItemPosted( item ) && !this.withinPostBlackoutWindow( item ) ?
                <Tooltip title={this.props.deleteTooltipTextGetter(item)}>
                  <IconButton onClick={() => this.props.onDeleteItem(item)}>
                    <Delete fontSize='small'/>
                  </IconButton>
                </Tooltip>
              :
                null
            }
          </div>
        </Grid>
      </Grid>
    )
  }

  line = ( item, index ) => {
    return(
      <Grid
        container
        item
        xs={12}
        style={{
          marginBottom: 15,
          opacity: item.shouldPost || item.isPosted ? 1 : 0.5
        }}
      >
        <Grid item xs={1}>
          <span className={this.props.classes.line}></span>
        </Grid>

        <Grid item xs={9} style={{ paddingTop: '2.5%', paddingBottom: '2.5%' }}>
          <div style={{ width: 'fit-content' }}>
            { this.props.timelineContent(item) }

            {
              this.props.showPostButton &&
              this.props.currentUserRole.isCompanyRole() &&
              !this.props.isFeedItemPosted( item ) &&
              !this.withinPostBlackoutWindow( item ) &&
              ( !item.shouldPost || this.isPastPost( item ) ) ?
                <FacebookPostButton
                  onClick={() => this.props.onPostItem( item )}
                />
              :
                null
            }
          </div>
        </Grid>
      </Grid>
    )
  }


  render() {
    const sortedItems = this.props.items.sort(
      (a, b) => d3.ascending(a.postAt, b.postAt)
    );

    const monthsAndYears = {
      formattedDates: [],
      indices: []
    };

    sortedItems.forEach(
      (i, index) => {
        const formattedDate = Moment.utc( i.postAt.toString() ).local().format('MMMM YYYY');

        if( !monthsAndYears.formattedDates.includes( formattedDate ) ){
          monthsAndYears.formattedDates.push( formattedDate );
          monthsAndYears.indices.push( index );
        }
      }
    );

    return(
      <Grid container>
        <Grid item xs={12}>
          <div style={{ paddingBottom: 25 }}>
            <span
              className={this.props.classes.dot}
              style={{
                backgroundColor: this.props.theme.palette.primary.main
              }}
            />

            <span>Owned Feed Item</span>

            <span
              className={this.props.classes.dot}
              style={{
                backgroundColor: '#bbb',
                marginLeft: 15
              }}
            />

            <span>Inherited Feed Item</span>
          </div>
        </Grid>

        <Grid item sm={12} md={6} className={this.props.classes.feed}>
          {
            this.props.isLoading ?
              <LinearProgress />
            :
              sortedItems.map(
                (item, i) => (
                  <React.Fragment key={i}>
                    {
                      monthsAndYears.indices.includes( i ) ?
                        <Typography key={i} variant="h4" id={`formatted-date-${monthsAndYears.indices.indexOf( i )}`}>
                          { monthsAndYears.formattedDates[ monthsAndYears.indices.indexOf( i ) ] }
                        </Typography>
                      :
                        null
                    }

                    { this.dot(item, i) }

                    { this.line(item, i) }
                  </React.Fragment>
                )
              )
          }
        </Grid>

        <Grid item sm={12} md={6} className={this.props.classes.timeline}>
          <div className={this.props.classes.postSchedule}>
            <List component={Paper}>
              <ListSubheader className={this.props.classes.postScheduleLabel}>
                Feed Item Post Schedule
              </ListSubheader>

              <Divider />

              {
                monthsAndYears.formattedDates.map(
                  (t, i) => (
                    <>
                      <ListItem key={i}>
                        <Link href={`#formatted-date-${i}`}>
                          { t }
                        </Link>
                      </ListItem>

                      {
                        i !== monthsAndYears.formattedDates.length - 1 ?
                          <Divider />
                        :
                          null
                      }
                    </>
                  )
                )
              }
            </List>
          </div>
        </Grid>
      </Grid>
    )
  }
}

SocialTimeline.defaultProps = {
  items: [],
  timelineContent: () => {},
  fetchEditLink: () => '',
  onDeleteItem: () => {},
  deleteTooltipTextGetter: feedItem => {'Delete Feed Item'},
  showViewButtonForItem: item => item && item.isPosted,
  isFeedItemPosted: item => item && item.isPosted,
  showEditButton: true,
  showDeleteButton: true,
  showPostButton: true,
  onPostItem: () => {},
  onRestoreItem: () => {},
  fetchViewLink: () => '',
  isLoading: false,
}

const styles = theme => ({
  dot: {
    height: 15,
    width: 15,
    borderRadius: 7.5,
    display: 'inline-block',
    marginRight: 15,
    marginTop: 14
  },
  line: {
    height: '100%',
    width: 15,
    backgroundColor: '#bbb',
    borderRadius: 7.5,
    display: 'inline-block',
  },
  timestamp: {
    position: 'relative',
    top: 4,
    display: 'inline-block'
  },
  feed: {
    order: 1,

    [theme.breakpoints.down(`${960}`)]: {
      order: 2,
    },
  },
  timeline: {
    order: 2,

    [theme.breakpoints.down(`${960}`)]: {
      order: 1,
    },
  },
  postSchedule: {
    position: 'fixed',
    width: '35%',

    [theme.breakpoints.down(`${960}`)]: {
      position: 'inherit',
      marginBottom: 15,
      width: '100%'
    },
  },
  postScheduleLabel: {
    position: 'relative'
  }
})

export default withContext(
  UserRoleContext,
  withStyles(styles, { withTheme: true })(SocialTimeline)
)