import React from 'react';
import Axios from 'axios';

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton, Button, withStyles, MenuItem, Typography, DialogContentText, LinearProgress } from '@material-ui/core';

import { SnackbarContext, Alert, UserRoleContext } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { CompanyVirtualProfileContext } from 'Virtual/CompanyVirtual/CompanyVirtualProfile/CompanyVirtualProfileProvider';
import MeetingRoomModel from './MeetingRoomModel';



export const ROOM_MODES = {
  'normal': 'Normal (1-4)',
  'group': 'Group (5+)'
};


class EmbedVirtualMeetingRoomDialog extends React.Component {
  state = {
    meetingRoom: null,
    loading: false
  }



  componentDidUpdate = oldProps => {
    if( this.props.open && this.props.open !== oldProps.open ){
      return this.setState(
        { loading: true, meetingRoom: null },
        this.fetchMeetingRoom
      )
    }
  }



  fetchMeetingRoom = () => {
    if( !this.props.meetingRoom ){
      this.setState({ loading: false });

      return Promise.resolve();
    }


    return Axios.get(
      `/api/virtual/meeting_rooms/${ this.props.meetingRoom.id }`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const meetingRoom = MeetingRoomModel.fromJSON(
        response.data.meeting_room
      );


      return this.setState({
        meetingRoom,
        loading: false
      });
    });
  }



  createEmbedScriptString = () => (
    `<div id="go-boost-meeting-embed"></div>\n<script async src="https://storage.googleapis.com/go-boost-partners-public/virtual/go-boost-video-embed.min.js" data-gb-color="${ this.props.companyVirtualProfile.primaryColor || '#51C0A8' }" data-gb-embed-id="${ this.state.meetingRoom ? this.state.meetingRoom.embedId : '' }"></script>`
  );



  copyToClipboard = () => navigator.clipboard.writeText(
    this.createEmbedScriptString()
  ).then(
    () => this.props.showSnackbar('Script tag successfully copied to your clipboard.')
  )



  render(){
    const { classes } = this.props;

    if( !this.props.meetingRoom ){
      return <></>;
    }


    return(
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>
          Embed Meeting Room On Your Website
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            Before this Meeting Room can go live, the following code must be on your website.
            Note, if your website is hosted on GoBoost and you use your Site domain, this step
            is done automatically. Check your email for more details about this Meeting Room.
            Place this script tag on the webpage that will host your Meeting Room.
            Embed your Meeting Room on your website by providing an anchor element, such as a
            div or span, with the id attribute set to 'go-boost-meeting-embed'.
            Note: this Meeting Room will only work on { this.props.meetingRoom.url }.
          </DialogContentText>

          {
            this.state.loading ?
              <LinearProgress />
            :
              <TextField
                variant="outlined"
                multiline
                value={this.createEmbedScriptString()}
                className={classes.codeBlock}
              />
          }
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={this.copyToClipboard}
            color='primary'
            disabled={this.state.loading}
          >
            Copy to clipboard
          </Button>

          <Button
            variant='contained'
            onClick={this.props.onClose}
            color='primary'
            className={classes.cancelButton}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}


EmbedVirtualMeetingRoomDialog.defaultProps = {
  open: false,
  onClose: () => {},
  meetingRoom: ''
}


const styles = theme => ({
  dialogContent: {
    paddingTop: 10
  },
  textField: {
    marginBottom: 30
  },
  cancelButton: {
    marginRight: 15,
    marginLeft: 15,
  },
  codeBlock: {
    width: '100%',
    backgroundColor: 'whitesmoke',
    marginBottom: 30
  },
  dialogContentText: {
    marginBottom: 15
  }
})


export default withContext(
  CompanyVirtualProfileContext,
  SnackbarContext,
  UserRoleContext,
  withStyles(styles)(
    EmbedVirtualMeetingRoomDialog
  )
)