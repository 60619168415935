import React from 'react';

import { log, MaxWidth, withContext } from 'kn-react';
import { PromiseButton, PlanContext, Ability, CompanyRoleContext } from 'go-boost-library-react';

import { Button, Grid, LinearProgress, Typography, withStyles } from '@material-ui/core';

import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import AdsUndoResubscriptionAlert from 'Ads/CompanyAds/AdsUndoResubscriptionAlert/AdsUndoResubscriptionAlert';
import boostStrategies from '../../../BoostedServices/BoostStrategy/boostStrategies';
import BoostStrategyForm from '../../../BoostedServices/BoostStrategy/BoostStrategyForm/BoostStrategyForm';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';

class BoostStrategySetup extends React.Component {
  state = {
    boostedService: {},
    companyBoostedService: null,
    boostStrategyName: null,
    boostTotalCents: null,
    monthlyMaxCents: null,
    autoRenewThresholdCents: null,
  }

  componentDidMount = () => {
    const boostedServiceId = parseInt(this.props.match.params.id);
    const { boostedServices, companyBoostedServices } = this.props;
    const boostedService = boostedServices.find(b => b.id == boostedServiceId);
    const companyBoostedService = companyBoostedServices.find(c => c.boostedServiceId === boostedServiceId);


    const { selectedPlanId } = this.props;
    const plans = this.props.upgradableAdsPlans;

    let plan;
    if(selectedPlanId) {
      plan = plans.find(p => p.id === selectedPlanId);
    } else {
      plan = this.props.subscribedAdsPlan;
    }

    this.setState({
      boostedService,
      plan,
      companyBoostedService: companyBoostedService,
      boostStrategyName: companyBoostedService.boostStrategyName || 'starter',
      // Note that here we set the `initialBoostTotalCents`
      boostTotalCents: [null, undefined].includes(companyBoostedService.initialBoostTotalCents) ? 50000 : companyBoostedService.initialBoostTotalCents,
      monthlyMaxCents: companyBoostedService.monthlyMaxCents || null,
      autoRenewThresholdCents: null,
    });
  }


  onBoostStrategyChange = newBoostStrategy => {
    log('onBoostStrategyChange', newBoostStrategy);
    this.setState(newBoostStrategy);
  }


  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions, { boostedServiceId: this.state.boostedService.id });
  }

  onClickSave = () => {
    const { boostStrategyName, boostTotalCents, monthlyMaxCents, autoRenewThresholdCents } = this.state;
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;
    const isStarter = boostStrategyName == boostStrategies.starter.name;

    const autoRenewAmountCents = isStarter ? null : boostTotalCents;
    const initialBoostTotalCents = boostTotalCents;

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      'selected_boost_strategy',
      getUserRoleAuthHeaders,
      {
        boostStrategyName: boostStrategyName,
        boostTotalCents: boostTotalCents,
        monthlyMaxCents: monthlyMaxCents,
        autoRenewThresholdCents: autoRenewThresholdCents,
        autoRenewAmountCents: autoRenewAmountCents,
        isStarter: isStarter
      }
    );

    const updatedProperties = {
      boostStrategyName,
      initialBoostTotalCents,
      autoRenewAmountCents,
      monthlyMaxCents,
      autoRenewThresholdCents, // Currently defaults to null
    };

    if( this.state.companyBoostedService && !this.state.companyBoostedService.isActivated ){
      updatedProperties.isActivated = true;
    }

    return this.props.updateCompanyBoostedService(
      this.state.companyBoostedService.id,
      updatedProperties
    )
    .then(() => this.props.transitionTo('next', this.props.transitions, { boostedServiceId: this.state.boostedService.id }));
  }

  render(){
    const { classes } = this.props;
    const { boostedService, boostStrategyName, companyBoostedService } = this.state;

    if(!boostStrategyName) {
      return <LinearProgress/>;
    }

    const isShutdown = (
      this.props.companyAdsProfile &&
      this.props.companyAdsProfile.isSetupComplete &&
      this.props.companyAdsProfile.isShutdown
    );

    return (
      <MaxWidth maxWidth={600}>
        <div className={classes.adsUndoResubscriptionAlert}>
          <AdsUndoResubscriptionAlert />
        </div>

        <MaxWidth maxWidth={500}>
          <div className={classes.root}>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <Typography variant="h5">Boost Strategy</Typography>
                <Typography variant="caption">Configure your spend on your <span className={classes.colorPrimary}>{ boostedService.name }</span> ads.</Typography>
              </Grid>

              <CancelSetupButton
                to={this.props.cancelTo}
              />

              <Grid item xs={12}>
                <BoostStrategyForm
                  boostStrategyName={this.state.boostStrategyName}
                  boostTotalCents={this.state.boostTotalCents}
                  monthlyMaxCents={this.state.monthlyMaxCents}
                  onBoostStrategyChange={this.onBoostStrategyChange}
                  plan={this.state.plan}
                />
              </Grid>

              <Grid item xs={6}>
                <Button variant="outlined" color="primary" onClick={this.onClickBack}>
                  Back
                </Button>
              </Grid>

              {
                this.props.currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_BILLING ) ?
                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                      <PromiseButton
                        buttonProps={{
                          variant: 'contained',
                          color: 'primary',
                          fullWidth: false,
                          disabled: isShutdown
                        }}
                        onProcess={
                          isShutdown ?
                            () => Promise.resolve()
                          :
                            this.onClickSave
                        }
                        id="complete-boosted-strategy-update"
                      >
                        { companyBoostedService.isConfigured() ? 'Save' : 'Done' }
                      </PromiseButton>
                    </Grid>
                  :
                    null
              }

            </Grid>
          </div>
        </MaxWidth>
      </MaxWidth>
    );
  }

}


BoostStrategySetup.defaultProps = {
  cancelTo: '/'
}


const styles = theme => ({
  root: {
    padding: 16,
  },
  colorPrimary: {
    color: theme.palette.primary.main
  },
  adsUndoResubscriptionAlert: {
    width: '100%',
    paddingTop: 15
  }
});


export default withStyles(styles)(
  withContext(
    CompanyRoleContext,
    TransitionContext,
    PlanContext,
    BoostStrategySetup
  )
);
