import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Map as MapIcon, Dashboard as DashboardIcon, Call as CallIcon, Work as WorkIcon, Settings, Brush } from '@material-ui/icons';

import { SubNavigation, UserRoleContext, Ability } from 'go-boost-library-react';
import { withContext } from 'kn-react';

import { CompanySitesProfileContext } from './CompanySitesProfile/CompanySitesProfileProvider';
import AppNavigation from '../../AppNavigation/AppNavigation';
import CompanyJob from './CompanyJob/CompanyJob';
import CompanyJobs from './CompanyJobs/CompanyJobs';
import CompanyServiceArea from './CompanyServiceArea/CompanyServiceArea';
import ProductShutdownAlert from 'CompanyRole/productShutdownAlert';
import SiteDesignsSetupProcess from './SiteDesigns/SiteDesignsSetupProcess';
import SitesDashboard from './SitesDashboard/SitesDashboard';
import SiteSettings from './SiteSettings/SiteSettings';
import SitesNotSetup from './SitesSetup/SitesNotSetup/SitesNotSetup';
import SitesOpportunities from './SitesOpportunities/SitesOpportunities';
import SitesSetupProcess from './SitesSetup/SitesSetupProcess';
import StripePaymentAlert from 'CompanyRole/stripePaymentAlert';
import UnpublishedCurrentSiteAlert from 'Sites/CompanySites/UnpublishedCurrentSiteAlert/UnpublishedCurrentSiteAlert';


class CompanySites extends React.Component {

  render(){
    var subNavLinks = [
      { primaryText: 'Dashboard', name:'dashboard', path: '/sites', exactPathMatch: true, icon: <DashboardIcon/> },
      { primaryText: 'Jobs', path: '/sites/jobs', icon: <WorkIcon/> },
      { primaryText: 'Opportunities', name:'opportunities', path: '/sites/opportunities', icon: <CallIcon/> },
      { primaryText: 'Site Designs', name:'site_designs', path: '/sites/site_designs', icon: <Brush/> },
      { primaryText: 'Service Area', name:'service_area', path: '/sites/service_area', icon: <MapIcon/> },
      { primaryText: 'Site Settings', name:'site_settings', path: '/sites/site_settings', icon: <Settings/> },
    ];

    const { companySitesProfile, currentUserRole } = this.props;

    const canEdit = currentUserRole.roleHasAbility(Ability.EDIT_SITES_COMPANY_SITE_DESIGN);

    return (
      <AppNavigation title="Sites">
        <Switch>
          <Route path="/sites/setup" component={SitesSetupProcess}/>
          <Route path="/sites/cannot_setup" component={SitesNotSetup}/>
          <Route path="/sites">
            <>
              {/* { companySitesProfile.isSetupComplete ? null : <Redirect to="/sites/setup"/> } */
                companySitesProfile && companySitesProfile.isSetupComplete ?
                  <SubNavigation links={subNavLinks}>
                    <StripePaymentAlert/>
                    <ProductShutdownAlert
                      profile={companySitesProfile}
                      product='Sites'
                      onProcess={this.props.resubscribeToSites}
                    />

                    <UnpublishedCurrentSiteAlert />

                    <Switch>
                      <Route exact path="/sites" component={SitesDashboard}/>
                      <Route path="/sites/jobs/opportunities/:id" component={CompanyJob} />
                      <Route path="/sites/jobs" component={CompanyJobs} />
                      <Route path="/sites/opportunities" component={SitesOpportunities}/>
                      <Route path="/sites/site_designs" component={SiteDesignsSetupProcess}/>
                      <Route path="/sites/site_settings" component={SiteSettings}/>
                      <Route path="/sites/service_area" component={CompanyServiceArea}/>
                      <Route path="*" component={props => <Redirect to='/'/>} />
                    </Switch>
                  </SubNavigation>
                :
                  canEdit ?
                    <Redirect to="/sites/setup"/>
                  :
                   <Redirect to="/sites/cannot_setup"/>
              }
            </>
          </Route>
        </Switch>
      </AppNavigation>
    );
  }

}




export default withContext(UserRoleContext, CompanySitesProfileContext, CompanySites);