import React from 'react';
import Axios from 'axios';

import { AutoError, formHelpers, withProvider, withContext } from 'kn-react';
import { PromiseButton, SnackbarContext, MegaphoneIcon, ReviewsStarIcon, UserRoleContext, PlanModel } from 'go-boost-library-react';

import { EmailOutlined, People as SitesIcon, People as SocialIcon } from '@material-ui/icons';
import { Grid, Paper, TextField, Typography, List, ListItem, ListItemAvatar, ListItemText, ListItemSecondaryAction, Switch, withStyles } from '@material-ui/core';

import { USER } from 'Activity/Category';
import ActivityLogList from 'Activity/ActivityLogList';
import AppNavigation from 'AppNavigation/AppNavigation';
import UserNotificationPreferencesProvider, { UserNotificationPreferencesContext } from 'UserNotificationPreferences/UserNotificationPreferencesProvider';


class Profile extends React.Component {
  state = {
    firstName: this.props.currentUserRole.userFirstName,
    lastName: this.props.currentUserRole.userLastName,
    email: this.props.currentUserRole.userEmail,
    oldPassword: '',
    password: '',
    confirmPassword: '',
    updatedAttributes: [],
    plans: []
  }

  componentDidMount = () => {
    return this.fetchProducts()
  }

  fetchProducts = () => {
    return Axios.get(
      `/api/core/users/${ this.props.currentUserRole.userId }/plans`,
      { headers: this.props.getUserRoleAuthHeaders() }
    ).then(response => {
      const plans = response.data.plans.map(
        p => PlanModel.fromJSON(p)
      );

      this.setState({ plans })
    })
  }

  onProcess = () => {
    return this.validate()
      .then(this.postData)
      .catch(AutoError.catch.bind(this));;
  }

  validate = () => {

    const passwordValidation = (!this.state.password.length) ? {} : {
      oldPassword: {
        presence: {message:'Please enter your current password to change your password.'}
      },
      password: {
        length: {atLeast:8, message:'Please enter a password that is at least 8 characters.'}
      },
      confirmPassword: {
        presence: { message: `Please confirm your new password.` },
        equalTo: { message: `Your password and confirm passwords do not match.`, value:this.state.password }
      }
    }

    const validations = {
      firstName: {
        presence: { message: `Please enter your first name.` }
      },
      lastName: {
        presence: { message: `Please enter your last address.` }
      },
      email: {
        presence: { message: `Please enter your email.` }
      },
      ...passwordValidation
    };

    return formHelpers.validate(this.state, validations);
  }

  postData = () => {
    const { updatedAttributes } = this.state;
    const attributes = {};
    updatedAttributes.forEach(attribute => {
      attributes[attribute] = this.state[attribute];
    });

    if (Object.keys(attributes).length) {
      this.props.updateUserProfile(attributes)
        .then(response => this.props.showSnackbar('Profile Info Successfully Updated'))
        .catch(AutoError.catch.bind(this))
    }
    this.setState({ updatedAttributes:[], oldPassword:'', password:'', confirmPassword:'' });
  }

  onError = error => {
    this.props.showSnackbar(error.response.data.message)
  }

  updateInformation = (field,newValue) => {
    this.setState({ [field]:newValue });
    if (!(field in this.state.updatedAttributes)) {
      const updatedAttributes = [...this.state.updatedAttributes, field];
      this.setState({ updatedAttributes });
    }
  }

  render(){
    const { classes } = this.props;

    return (
      <AppNavigation title="Profile">
        <Grid container spacing={16} style={{ padding: 16 }}>
          <Grid item xs={12}>
            <Typography variant="h5">Edit Your Information</Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ padding: 15 }}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    value={this.state.firstName}
                    onChange={e => this.updateInformation('firstName',e.target.value) }
                    onBlur={() => formHelpers.trim.call(this, 'firstName') }
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    value={this.state.lastName}
                    onChange={e => this.updateInformation('lastName',e.target.value)}
                    onBlur={() => formHelpers.trim.call(this, 'lastName')}
                    margin="normal"
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Old Password"
                    type="password"
                    autoComplete='new-password'
                    value={this.state.oldPassword}
                    onChange={e => this.updateInformation('oldPassword',e.target.value)}
                    margin="normal"
                    fullWidth
                  />
                  <Typography variant="caption">At least eight characters.</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Password"
                    type="password"
                    autoComplete='new-password'
                    value={this.state.password}
                    onChange={e => this.updateInformation('password',e.target.value)}
                    onBlur={() => formHelpers.trim.call(this, 'password')}
                    margin="normal"
                    fullWidth
                  />
                  <Typography variant="caption">At least eight characters.</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Confirm Password"
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={e => this.updateInformation('confirmPassword',e.target.value)}
                    onBlur={() => formHelpers.trim.call(this, 'confirmPassword')}
                    margin="normal"
                    fullWidth
                  />
                </Grid>


                <Grid item xs={12} style={{ textAlign: 'right' }}>
                  <PromiseButton
                    buttonProps={{
                      fullWidth: false,
                      variant: 'contained',
                      color: 'primary'
                    }}
                    onProcess={this.onProcess}
                  >
                    Update
                  </PromiseButton>
                </Grid>

              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper style={{ padding: 15 }}>
              <Typography variant="h5">Notification Preferences</Typography>

              <List className={classes.list}>
                <ListItem ContainerProps={{ className: classes.listItem }}>
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <EmailOutlined />
                  </ListItemAvatar>

                  <ListItemText>
                    Account
                  </ListItemText>

                  <ListItemSecondaryAction>
                    <Switch
                      checked={this.props.userNotificationPreferences.isCoreEmailsEnabled}
                      color="primary"
                      onChange={() => this.props.togglePreferences({
                        isCoreEmailsEnabled: !this.props.userNotificationPreferences.isCoreEmailsEnabled
                      })}
                    />
                  </ListItemSecondaryAction>
                </ListItem>

                {
                  this.state.plans.find(p => p.isAdsIncluded) ?
                    <ListItem ContainerProps={{ className: classes.listItem }}>
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <MegaphoneIcon />
                      </ListItemAvatar>

                      <ListItemText>
                        Ads
                      </ListItemText>

                      <ListItemSecondaryAction>
                        <Switch
                          checked={this.props.userNotificationPreferences.isAdsEmailsEnabled}
                          color="primary"
                          onChange={() => this.props.togglePreferences({
                            isAdsEmailsEnabled: !this.props.userNotificationPreferences.isAdsEmailsEnabled
                          })}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  :
                    null
                }

                {
                  this.state.plans.find(p => p.isReviewsIncluded) ?
                    <ListItem ContainerProps={{ className: classes.listItem }}>
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <ReviewsStarIcon />
                      </ListItemAvatar>

                      <ListItemText>
                        Reviews
                      </ListItemText>

                      <ListItemSecondaryAction>
                        <Switch
                          checked={this.props.userNotificationPreferences.isReviewsEmailsEnabled}
                          color="primary"
                          onChange={() => this.props.togglePreferences({
                            isReviewsEmailsEnabled: !this.props.userNotificationPreferences.isReviewsEmailsEnabled
                          })}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  :
                    null
                }

                {
                  this.state.plans.find(p => p.isSitesIncluded) ?
                    <ListItem ContainerProps={{ className: classes.listItem }}>
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <SitesIcon />
                      </ListItemAvatar>

                      <ListItemText>
                        Sites
                      </ListItemText>

                      <ListItemSecondaryAction>
                        <Switch
                          checked={this.props.userNotificationPreferences.isSitesEmailsEnabled}
                          color="primary"
                          onChange={() => this.props.togglePreferences({
                            isSitesEmailsEnabled: !this.props.userNotificationPreferences.isSitesEmailsEnabled
                          })}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  :
                    null
                }

                {
                  this.state.plans.find(p => p.isSocialIncluded) ?
                    <ListItem ContainerProps={{ className: classes.listItem }}>
                      <ListItemAvatar className={classes.listItemAvatar}>
                        <SocialIcon />
                      </ListItemAvatar>

                      <ListItemText>
                        Social
                      </ListItemText>

                      <ListItemSecondaryAction>
                        <Switch
                          checked={this.props.userNotificationPreferences.isSocialEmailsEnabled}
                          color="primary"
                          onChange={() => this.props.togglePreferences({
                            isSocialEmailsEnabled: !this.props.userNotificationPreferences.isSocialEmailsEnabled
                          })}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  :
                    null
                }
              </List>


            </Paper>
          </Grid>
        </Grid>

        <ActivityLogList
          userRoleIds={ [ this.props.currentUserRole.id ] }
          categories={ [ USER ] }
          tableToolbarCaption={
            <>
              Previous user profile activity.
            </>
          }
        />
      </AppNavigation>
    );
  }

}


const styles = theme => ({
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignContent: 'stretch',
  },
  listItem: {
    width: '50%'
  },
  listItemAvatar: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
})


export default withProvider(
  UserNotificationPreferencesProvider,
  withContext(
    UserRoleContext,
    UserNotificationPreferencesContext,
    SnackbarContext,
    withStyles(styles)(Profile)
  )
)