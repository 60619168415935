import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Material
import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import SendIcon from '@material-ui/icons/Send';
import CodeIcon from '@material-ui/icons/Code';
// Kinertia
import { withContext, withProvider } from 'kn-react';
import { SubNavigation, SnackbarContext, ReviewsStarIcon, UserRoleContext, Ability } from 'go-boost-library-react';
// Providers
import CompanyReviewsProfileProvider from 'Reviews/CompanyReviews/CompanyReviewsProfile/CompanyReviewsProfileProvider';
import FacebookAccountProvider from 'FacebookAccount/FacebookAccountProvider';
import GoogleAccountProvider from 'GoogleAccount/GoogleAccountProvider';
import ReviewSourcesProvider from 'Reviews/ReviewSourcesProvider';
// Context
import { CompanyReviewsProfileContext } from './CompanyReviewsProfile/CompanyReviewsProfileProvider';
// App
import AppNavigation from '../../AppNavigation/AppNavigation';
import CompanyReviewDetails from './CompanyReviewDetails/CompanyReviewDetails';
import CompanyReviewRequestDetails from './CompanyReviewRequestDetails/CompanyReviewRequestDetails';
import CompanyReviewRequestsTable from './ReviewRequests/CompanyReviewRequestsTable';
import NewReviewRequest from './ReviewRequests/NewReviewRequest';
import ReviewsDashboard from './ReviewsDashboard/ReviewsDashboard';
import ReviewsNotSetup from './ReviewsNotSetup/ReviewsNotSetup';
import ReviewsSettings from './ReviewsSettings/ReviewsSettings';
import ReviewsSetupProcess from './ReviewsSetup/ReviewsSetupProcess';
import ReviewsTable from 'Reviews/ReviewsTable/ReviewsTable';
import StripePaymentAlert from 'CompanyRole/stripePaymentAlert';
import EmbedYourReviews from './ReviewsEmbedding/EmbedYourReviews';
import ProductShutdownAlert from 'CompanyRole/productShutdownAlert';


class CompanyReviews extends React.Component {


  render() {
    var subNavLinks = [
      { primaryText: 'Dashboard', path: '/reviews', exactPathMatch: true, icon: <DashboardIcon /> },
      { primaryText: 'Reviews', path: '/reviews/all', icon: <ReviewsStarIcon />, isActive: () => /\/reviews\/all|\/reviews\/\d+/.test(window.location.pathname) },
      { primaryText: 'Requests', path: '/reviews/review_requests', icon: <SendIcon /> },
      { primaryText: 'Reviews Settings', path: '/reviews/settings', icon: <SettingsIcon /> },
      { primaryText: 'Embed Your Reviews', path: '/reviews/embed', icon: <CodeIcon /> },
    ];

    const { companyReviewsProfile, currentUserRole } = this.props;

    const canEdit = (currentUserRole.roleHasAbility( Ability.EDIT_COMPANY_REVIEWS )) ? true : false;

    return (
      <AppNavigation title="Reviews">
        <Switch>
          <Route path="/reviews/setup" component={ReviewsSetupProcess} />
          <Route path="/reviews/cannot_setup" component={ReviewsNotSetup} />
          <Route path="/reviews">
            <>
              {/*
                Redirect to setup process if companyReviewsProfile is not completed

                Redirect to home if user does not have proper ability. This route must go last because
                it get overwritten by other redirect otherwise.
              */}
              {
                companyReviewsProfile && companyReviewsProfile.isSetupComplete ?
                  <SubNavigation links={subNavLinks}>
                    <StripePaymentAlert/>
                    <ProductShutdownAlert
                      profile={companyReviewsProfile}
                      product='Reviews'
                      onProcess={this.props.resubscribeToReviews}
                    />

                    <Switch>
                      <Route exact path="/reviews" component={ReviewsDashboard} />
                      <Route exact path="/reviews/all" component={ReviewsTable} />
                      <Route exact path="/reviews/review_requests" component={CompanyReviewRequestsTable} />
                      <Route path="/reviews/review_requests/new" component={NewReviewRequest} />
                      <Route exact path="/reviews/review_requests/:id(\d+)" component={CompanyReviewRequestDetails} />
                      <Route path="/reviews/settings" component={ReviewsSettings} />
                      <Route path="/reviews/embed" component={EmbedYourReviews} />
                      <Route exact path="/reviews/:id(\d+)" component={CompanyReviewDetails} />
                      <Route path="*" component={props => <Redirect to='/'/>} />
                    </Switch>
                  </SubNavigation>
                :
                  canEdit ?
                    <Redirect to="/reviews/setup" />
                  :
                    <Redirect to="/reviews/cannot_setup" />
                }

            </>
          </Route>
        </Switch>
      </AppNavigation>
    );
  }

}





export default withProvider(
  CompanyReviewsProfileProvider,
  ReviewSourcesProvider,
  GoogleAccountProvider,
  FacebookAccountProvider,
  withContext(UserRoleContext, CompanyReviewsProfileContext, SnackbarContext, CompanyReviews)
);