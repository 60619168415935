import React from 'react';

import { format, withContext, log } from 'kn-react';
import { OverTimeChart, BarChart, CostChart, ChangeMetric, KnPieChart, HandClickIcon, ClickThroughRateIcon, FormIcon, OpportunityModel } from 'go-boost-library-react';

import { MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import withStyles from '@material-ui/core/styles/withStyles';

import { ChannelContext } from 'Channels/ChannelProvider';
import CompanyAutosuggestDialog from 'Settings/Users/UserDetails/NewOrganizationUserRoleDialog/CompanyAutosuggest/CompanyAutosuggestDialog';
import FetchedOpportunitiesTable from 'Opportunities/FetchedOpportunitiesTable';


class AdsDashboardContent extends React.Component {


  noData = () => {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
          minHeight: 100,
          textAlign: 'center'
        }}
      >
        <Typography
          style={{
            color:'#ccc',
            position:'absolute',
            height: '20%',
            width: '100%',
            top: '40%',
            bottom: '40%',
            marginLeft: 'auto',
            marginRight: 'auto'
          }} variant="h5">
            You do not have any data to display at this time
          </Typography>
      </div>

    )
  }

  render() {
    let {
      viewClickLineStyle,
      viewClickYAxis,
      ctrDataStyles,
      ctrDataYAxis,
      ctrDataTooltipProps,
      theme
    } = this.props;

    viewClickLineStyle = viewClickLineStyle || {
      Views: {
        type:"monotone",
        dataKey:"Views",
        stroke:theme.palette.primary.main,
        activeDot: { r: 8 },
        yAxisId:"left"
      },
      Clicks: {
        type: 'monotone',
        dataKey: 'Clicks',
        stroke:theme.palette.secondary.main,
        activeDot: { r:8 },
        yAxisId:"right"
      },
    };

    ctrDataStyles = ctrDataStyles || {
      'Clicks': {
        dataKey: 'CTR',
        fill: theme.palette.primary.main,
        yAxisId: 'left'
      }
    };

    const pieChartData = this.props.opportunityTypeDistribution.map(d => ({
      name: OpportunityModel.opportunityTypeDisplayName[d.opportunityType],
      value: d.count,
    }));


    return (
      <Grid container spacing={16}>

        <Grid item xs={12}>
          <Typography variant="h6">Ads Dashboard</Typography>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            select
            label="Time Period"
            value={ this.props.timePeriod }
            onChange={e => this.props.onChangeTimePeriod(e.target.value) }
            fullWidth
          >
             {
              this.props.timePeriodOptions.map(t => (
                <MenuItem value={t} key={t.label}>{ t.label }</MenuItem>
              ))
            }
          </TextField>
        </Grid>


        <Grid item xs={12} sm={3}>
          <TextField
            select
            label="Channel"
            value={ this.props.channel || '' }
            onChange={e => this.props.onChangeChannel(e.target.value)}
            fullWidth
          >
            {
              this.props.channelOptions.map(c => (
                <MenuItem value={c} key={c.name}>{ c.name }</MenuItem>
              ))
            }
          </TextField>
        </Grid>


        {
          this.props.isSearchCompaniesVisible ?
            <Grid item xs={12} sm={3}>
              <CompanyAutosuggestDialog
                initialCompanies={this.props.companies}
                roleTypeId={this.props.roleTypeId}
                onChangeCompanies={this.props.onChangeCompanies}
              />
            </Grid>
          : null
        }



        <Grid item xs={12}>

          <Paper>
          <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <ChangeMetric
                  title="Views"
                  metric={ format.commas(this.props.totalViews) }
                  icon={VisibilityIcon}
                  iconColor="#37caad"
                  iconBackgroundColor="rgba(55, 202, 173, 0.2)"
                  change={this.props.totalViewsChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ChangeMetric
                  title="Clicks"
                  metric={ format.commas(this.props.totalClicks) }
                  icon={HandClickIcon}
                  iconColor="#5a7af9"
                  iconBackgroundColor="rgba(90, 122, 249, 0.2)"
                  change={this.props.totalClicksChange}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <ChangeMetric
                  title="CTR"
                  metric={ isNaN(this.props.totalCTR) ? '-' : format.percent(this.props.totalCTR) }
                  icon={ClickThroughRateIcon}
                  iconColor="#ff9a48"
                  iconBackgroundColor="rgba(255, 154, 72, 0.2)"
                  change={this.props.totalCTRChange}
                />
              </Grid>


              <Grid item xs={12} sm={6} md={3}>
                <ChangeMetric
                  title="Opportunities"
                  metric={ format.commas(this.props.totalOpportunities) }
                  icon={FormIcon}
                  iconColor="#9e70ff"
                  iconBackgroundColor="rgba(158, 112, 255, 0.2)"
                  change={this.props.totalOpportunitiesChange}
                />
              </Grid>
              </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={12} >
          <Paper style={{ padding: 15 }}>
            <Typography variant="h6">View / Clicks over time</Typography>
              <OverTimeChart
                width={'100%'}
                height={350}
                data={this.props.adsChartData}
                lineStyles={viewClickLineStyle}
                yAxes={viewClickYAxis}
              />
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Paper style={{ padding: 15, minHeight: 379 }}>
            <Typography variant="h6">Opportunity Type</Typography>
              <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 300 }}>
                <KnPieChart
                  data={pieChartData}
                  width={300}
                  height={300}
                  innerRadius={75}
                  total={format.commas(this.props.totalOpportunities)}
                  centerLabel={ this.props.totalOpportunities === 1 ? 'Opportunity' : 'Opportunities'}
                />
              </div>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Paper style={{ padding: 15, height: 379 }}>
            <Typography variant="h6">Click Through Rate (CTR)</Typography>
              <BarChart
                data={this.props.adsChartData}
                barStyles={ctrDataStyles}
                yAxis={ctrDataYAxis}
                tooltipProps={ctrDataTooltipProps}
                height={375}
              />
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Paper style={{ padding: 15, height: 379 }}>
            <CostChart
              displayAmount={format.money(this.props.totalCost)}
              data={this.props.adsChartData}
              mainColor={this.props.theme.palette.primary.main}
            />
          </Paper>
        </Grid>

        {/*
          <Grid item xs={12} sm={6} >
            <Paper style={{ padding: 15 }}>
              <Typography variant="h6">Device Type</Typography>
              <DeviceTypeTable
                desktopVisitors={devicesData.desktop.visitors}
                mobileVisitors={devicesData.mobile.visitors}
                tabletVisitors={devicesData.tablet.visitors}
                otherVisitors={devicesData.other.visitors}
              />
            </Paper>
          </Grid>
        */}


        <Grid item xs={12} >
          <FetchedOpportunitiesTable
            getUserRoleAuthHeaders={this.props.getUserRoleAuthHeaders}
            rowsPerPage={5}
            rowsPerPageOptions={[5]}
            startDate={this.props.opportunitiesStartDate}
            roleTypePath={this.props.roleTypePath}
            roleTypeId={this.props.roleTypeId}
            channels={this.props.channel && this.props.channel.id !== 'all' ? [ this.props.channel ] : this.props.adsChannels.filter(c => c.id !== 'all')}
            isSpamFiltered={true}
            companyIds={
              this.props.companies ?
                this.props.companies.map(c => c.id)
              :
                null
            }
            // actions={<Typography variant="body1">These opportunity exclude spam.</Typography>}
          />
        </Grid>

      </Grid>
    )
  }
}

const styles = theme => ({
  noData: {

  }
})

AdsDashboardContent.defaultProps = {
  isSearchCompaniesVisible: true,
  channel: '',
  companies: [],
  // views:null,
  // viewsPercentChange:null,
  // clicks:null,
  // clicksPercentChange:null,
  // ctr:null,
  // ctrPercentChange:null,
  // opportunities:null,
  // opportunityDataColors: null,
  // opportunitiesPercentChange:null,
  // viewClickData: null,
  // viewClickLineStyle: null,
  viewClickYAxis: [
    {
      yAxisId:"left"
    },
    {
      yAxisId:"right",
      orientation:"right"
    }
  ],

  ctrDataYAxis: {
    yAxisId:"left",
    tickFormatter: p => {
      return format.percent(p);
    }
  },
  ctrDataTooltipProps: {
    formatter: p => {
      return format.percent(p);
    }
  },
}

export default withContext(
  ChannelContext,
  withStyles(styles, {withTheme: true})(AdsDashboardContent)
)

