import React from 'react';
import { withProvider } from 'kn-react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Material
import DashboardIcon from '@material-ui/icons/Dashboard';
// Kinertia
import { SubNavigation } from 'go-boost-library-react';
// App
import AppNavigation from '../../AppNavigation/AppNavigation';
import VirtualDashboard from './VirtualDashboard/VirtualDashboard';

import ChannelProvider from 'Channels/ChannelProvider';


class OrganizationVirtual extends React.Component {

  render() {
    var subNavLinks = [
      { primaryText: 'Dashboard', path: '/virtual', exactPathMatch: true, icon: <DashboardIcon /> },
    ];


    return (
      <AppNavigation title="Virtual">
        <SubNavigation links={subNavLinks}>
          <Switch>
            <Route exact path="/virtual" component={VirtualDashboard} />
            <Route path="*" component={props => <Redirect to='/'/>} />
          </Switch>
        </SubNavigation>
      </AppNavigation>
    );
  }

}

export default withProvider(
  ChannelProvider,
  OrganizationVirtual
);