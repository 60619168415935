import React from 'react';
import { Button, Grid, Paper, Tooltip, Typography, withStyles } from '@material-ui/core';
import { MaxWidth, withContext, log } from 'kn-react';

import { CompanySitesProfileContext } from 'Sites/CompanySites/CompanySitesProfile/CompanySitesProfileProvider';
import { SiteDesignsContext } from 'Sites/CompanySites/SiteDesigns/SiteDesignsProvider';
import { trackCurrentUserSegmentEvent } from 'SharedSetup/setupHelpers';
import { TransitionContext } from 'TransitionProvider/TransitionProvider';
import CancelSetupButton from 'SharedSetup/CancelSetupButton/CancelSetupButton';
import hashIdCoder from 'hashIdCoder';
import SiteDesignList from './SiteDesignList';
import SitesUndoResubscription from 'Sites/CompanySites/SitesSetup/SitesUndoResubscription/SitesUndoResubscription';
class ChooseSiteDesign extends React.Component {

  state = {
    initialSiteDesigns: this.props.companySiteDesigns
  }

  componentDidMount() {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;

    trackCurrentUserSegmentEvent(currentUserRole.userId, 'finished_sites_domain_step', getUserRoleAuthHeaders);

    trackCurrentUserSegmentEvent(
      currentUserRole.userId,
      'started_sites_design_step',
      getUserRoleAuthHeaders,
      { funnel: true }
    );
  }

  onClickBack = () => {
    return this.props.transitionTo('back', this.props.transitions);
  }

  onClickNext = () => {
    return this.props.transitionTo('next', this.props.transitions);
  }

  onClickSetup = siteDesign => {
    const { currentUserRole, getUserRoleAuthHeaders } = this.props;
    trackCurrentUserSegmentEvent(currentUserRole.userId, 'sites_selected_design', getUserRoleAuthHeaders);

    return this.props.findOrCreateCompanySiteDesign(siteDesign.id)
    .then(companySiteDesign => this.props.updateCompanySitesProfile({
      companySiteDesignId: companySiteDesign.id
    })).then(this.props.fetchCompanySiteDesigns)
    .then(() => {
      this.props.transitionTo('editSurvey', this.props.transitions, { siteDesignId: siteDesign.id });
    })
  }



  render(){
    const processingOnClickSetup = this.state.initialSiteDesigns.length !== this.props.companySiteDesigns.length;


    const { companySitesProfile, companySiteDesigns, selectedPlanId } = this.props;
    const activeCompanySiteDesign = companySiteDesigns.find( d => d.id === companySitesProfile.companySiteDesignId );

    const hasOnePlan = this.props.subscribedPlans.length === 1;

    const selectedPlanSiteDesigns = this.props.filterSiteDesignsByPlanId(selectedPlanId);

    return (
      <MaxWidth maxWidth={1200} style={{ padding: 16 }}>
        <Grid container spacing={16}>
          <Grid item xs={12}>
            <SitesUndoResubscription />
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h5">Pick a Design</Typography>
            <Typography variant="body2">Choose one of the designs below for your website.</Typography>
          </Grid>

          {/* <CancelSetupButton /> */}

          <Grid item xs={12}>
            <SiteDesignList
              selectedSiteDesignId={activeCompanySiteDesign ? activeCompanySiteDesign.siteDesignId : null }
              availableSiteDesigns={selectedPlanSiteDesigns}
              onClickSetup={this.onClickSetup}
              setupText='Select this design'
              onClickEditSettings={this.onClickEditSettings}
            />
          </Grid>

          {
            this.props.showBack ?
              <Grid item xs={6}>
                <Button color="primary" disabled={processingOnClickSetup} variant="outlined" onClick={this.onClickBack}>Back</Button>
              </Grid>
            : null
          }

          {
            this.props.companySiteDesigns.length && !processingOnClickSetup ?
                <Grid item xs={hasOnePlan ? 12 : 6} style={{ textAlign: 'right' }}>
                  <Button color="primary" disabled={processingOnClickSetup} variant="contained" onClick={() => this.props.transitionTo('next', this.props.transitions)}>Next</Button>
                </Grid>
              :
                null
          }

        </Grid>
      </MaxWidth>
    );
  }

}



ChooseSiteDesign.defaultProps = {
  showBack: true,
  selectedPlanId: null,
};



const styles = theme => ({
  root: {
    padding: 16,
  },
});

export default withStyles(styles)(
  withContext(
    CompanySitesProfileContext,
    SiteDesignsContext,
    TransitionContext,
    ChooseSiteDesign
  )
);
