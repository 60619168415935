import React from 'react';

import { PlanContext, CompanyRoleContext } from 'go-boost-library-react';
import { withContext, MaxWidth } from 'kn-react';

import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import ActivateOrGoToProduct from './Products/ActivateOrGoToProduct';
import AddToHomeScreenAlert from 'AddToHomeScreen/AddToHomeScreenAlert';
import AppNavigation from '../../AppNavigation/AppNavigation';
import StripePaymentAlert from 'CompanyRole/stripePaymentAlert';


class CompanyHome extends React.Component {

  render() {
    const { currentUserRole, classes } = this.props;

    let availableProductCount = 0;
    ['isReviewsAvailable', 'isAdsAvailable', 'isSitesAvailable', 'isSocialAvailable', 'isVirtualAvailable'].forEach(k => {
      availableProductCount += (this.props[k] ? 1 : 0);
    });

    let maxWidth = 1200;

    if( availableProductCount >= 5 ){
      maxWidth += 600;
    }

    return (
      <AppNavigation title="Home">
        <MaxWidth maxWidth={maxWidth} style={{ padding: 16 }}>
          <Grid container spacing={16}>
            <StripePaymentAlert/>

            <Grid item sm={12}>
              <Typography paragraph variant="h4" className={classes.greeting}>
                Welcome {currentUserRole.userFirstName}!
              </Typography>
            </Grid>

            <Grid item sm={12}>
              <AddToHomeScreenAlert/>
            </Grid>

            <ActivateOrGoToProduct />

          </Grid>
        </MaxWidth>
      </AppNavigation>
    );
  }

}


const styles = theme => ({
  greeting: {
    marginTop: 15,
    marginBottom: 15,
    textAlign: 'center'
  },

});

export default withStyles(styles)(
  withContext(
    PlanContext,
    CompanyRoleContext,
    CompanyHome
  )
);